<script lang="ts">
  import { tr } from '$lib/i18n'
</script>

<div
  class="inset-0 flex h-14 w-14 shrink-0 select-none flex-col items-center justify-center overflow-hidden bg-[transparent]"
>
  <div class="truncate text-[21px]">🎁</div>
  <div class="w-full truncate text-center text-[10px] font-normal text-white">
    {$tr('gifts')}
  </div>
</div>
