<script lang="ts">
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'
  import RoundGrayButton from '../ui/RoundGrayButton.svelte'

  export let content: string
  export let price: number
</script>

<div
  class="inset-0 flex h-14 w-14 shrink-0 select-none flex-col items-center justify-center overflow-hidden bg-[transparent]"
>
  <div class="truncate text-[21px]">{content}</div>
  <div class="w-full truncate text-center text-[10px] font-normal text-white">
    {price} <span class="inline-block translate-y-0.5"><CoinsIcon size={12} /></span>
  </div>
</div>
