<script lang="ts">
  import CoinFilledIcon from '$lib/assets/CoinFilledIcon.svelte'

  export let farmedCoins = 0
</script>

<div class="shrink-0">
  <div class="ml-2 flex flex-row items-center justify-end gap-0.5">
    <div
      class="relative bottom-[1.5px] text-[14px] font-medium text-white active:brightness-90"
      style="
        letter-spacing: var(--default-semibold-letter-spacing, -0.03em);
      "
    >
      {farmedCoins}
    </div>

    <div class="relative z-10 ml-[2px] overflow-visible">
      <CoinFilledIcon size={19}></CoinFilledIcon>
    </div>
  </div>
</div>
