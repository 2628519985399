<script lang="ts">
  export let size = 22
</script>

<svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12.9056 2.16536C12.3418 2.26551 11.8725 2.44979 11.4033 2.75826C11.107 2.95055 11.0247 3.02666 11.1193 3.02666C11.1399 3.02666 11.3128 3.13883 11.5021 3.27504C12.3912 3.91601 13.0703 4.84141 13.3996 5.85495C13.7865 7.04876 13.6836 8.49895 13.132 9.57258C13.0662 9.70077 13.025 9.81695 13.0374 9.83297C13.0867 9.88105 13.9429 9.88505 14.2474 9.83698C15.5398 9.63267 16.6635 8.80341 17.2356 7.62563C17.4784 7.12888 17.5937 6.6161 17.5937 6.01118C17.5978 5.17792 17.392 4.51291 16.9228 3.82788C16.3178 2.95055 15.367 2.34563 14.2968 2.16536C13.8811 2.09325 13.309 2.09325 12.9056 2.16536Z"
    fill="white"
  />
  <path
    d="M7.58321 3.56724C5.96977 3.91577 4.78438 5.10156 4.45099 6.69197C4.3769 7.0365 4.38925 7.78964 4.47569 8.17422C4.88316 10.041 6.64067 11.3671 8.57104 11.2669C8.80976 11.2509 9.15962 11.2028 9.35307 11.1587C9.76466 11.0586 10.3738 10.7862 10.7278 10.5418C11.0118 10.3455 11.4934 9.86478 11.695 9.58035C11.9132 9.27589 12.1848 8.67898 12.2836 8.29039C12.4071 7.79765 12.4112 6.91631 12.2836 6.46363C11.8926 5.06551 10.8883 4.05197 9.48889 3.64336C9.1802 3.55122 9.03202 3.53519 8.48872 3.52317C8.03597 3.51116 7.77255 3.52718 7.58321 3.56724Z"
    fill="white"
  />
  <path
    d="M16.9431 10.0772C16.8978 10.0933 16.7044 10.2094 16.5109 10.3296C16.3216 10.4498 16.0747 10.594 15.9594 10.6501C15.4573 10.8945 14.4571 11.1589 14.0373 11.1589C13.9344 11.1589 13.8438 11.1709 13.8315 11.1829C13.815 11.1949 13.9467 11.3271 14.1237 11.4754C16.0129 13.0978 17.0501 15.4013 17.0501 17.9772V18.49H18.3178C19.7502 18.49 19.8407 18.478 20.2482 18.2096C20.561 18.0053 20.8121 17.6768 20.9232 17.3202C21.0055 17.0638 21.0097 16.9757 20.9891 16.2746C20.9685 15.5055 20.9232 15.1609 20.7545 14.524C20.5157 13.6186 19.9889 12.6091 19.3551 11.8599C18.8447 11.255 18.038 10.574 17.4206 10.2215C17.1324 10.0572 17.0542 10.0332 16.9431 10.0772Z"
    fill="white"
  />
  <path
    d="M4.55463 11.6316C2.90002 12.597 1.66936 14.2315 1.20837 16.0783C1.04785 16.7273 0.986112 17.2801 1.00258 18.0092C1.01904 18.6101 1.02316 18.6382 1.15075 18.8986C1.33185 19.2751 1.59939 19.5395 1.98217 19.7238L2.29086 19.872L8.27954 19.8841C14.8033 19.8961 14.5193 19.9041 14.9268 19.6717C15.182 19.5275 15.5154 19.167 15.6429 18.9026C15.7541 18.6702 15.7541 18.6662 15.7541 17.7689C15.7541 16.9917 15.7417 16.8074 15.6594 16.4148C15.2602 14.4839 14.1447 12.8694 12.4572 11.7758C11.8439 11.3792 11.8234 11.3752 11.4035 11.6516C10.7656 12.0642 10.0659 12.3527 9.37438 12.4849C8.92986 12.573 7.73213 12.561 7.31642 12.4648C6.57555 12.2966 5.97874 12.0442 5.38604 11.6556C5.20906 11.5354 5.02384 11.4393 4.97034 11.4393C4.92094 11.4393 4.73161 11.5234 4.55463 11.6316Z"
    fill="white"
  />
</svg>
