<script lang="ts">
  import { api, apiCurrentUser } from '$lib/api'
  import BottomSheet2 from '$lib/components/BottomSheet2.svelte'
  import FullAvatar from '$lib/components/FullAvatar.svelte'
  import TelegramAvatar from '$lib/components/TelegramAvatar.svelte'
  import ReportButton from '$lib/components/Call/ReportButton.svelte'
  import { analyticsTrack } from '$lib/analytics'
  import Divider from '../Divider.svelte'
  import { tr } from '$lib/i18n'
  import type { ApiTypes } from '@twixer/core'
  import RoundGrayButton from '../ui/RoundGrayButton.svelte'
  import CrossIcon from '$lib/assets/CrossIcon.svelte'
  import OnlineDotIcon from '../ui/OnlineDotIcon.svelte'
  import InviteReferralButton from '../Referrals/InviteReferralButton.svelte'
  import HandIcon from '$lib/assets/HandIcon.svelte'

  $: friendsListPromise = getFriendsList()

  let pushed = false

  async function getFriendsList() {
    const friends = await $api.client.emitWithAck('user:get-friends')

    return friends
  }

  async function push(user: ApiTypes.Friend) {
    const pushResult = await $api.client.emitWithAck('friend:push', { userId: user.id })

    if (pushResult.ok) {
      Telegram.WebApp.HapticFeedback.impactOccurred('heavy')
    }
  }

  function unfriend(user: ApiTypes.Friend) {
    // @ts-ignore
    Telegram.WebApp.showConfirm($tr('friend_remove_confirm'), async (okPressed) => {
      if (!okPressed) return

      const result = await $api.client.emitWithAck('user:unfriend', { userId: user.id })

      if (!result.ok) {
        // @ts-ignore
        Telegram.WebApp.showAlert({
          title: $tr('error').error,
          message: $tr('error').friend_unable_to_unfriend,
        })
        return
      }

      friendsListPromise = getFriendsList()
    })
  }
</script>

<BottomSheet2 class="relative max-h-[60vh] overflow-y-scroll text-default" on:close>
  <div class="flex w-full flex-col items-center">
    <div class="mb-4 mt-6 flex w-full flex-col gap-4 px-4">
      <div class="flex flex-row items-center justify-center gap-3 font-medium">
        <div class="text-2xl font-semibold">{$tr('friends_list')}</div>
      </div>

      <InviteReferralButton />

      <Divider />
    </div>
  </div>

  <div class="flex select-none flex-col justify-between gap-3 overflow-y-scroll px-4 pb-4">
    {#await friendsListPromise then friendsList}
      {#if friendsList && friendsList.result && friendsList.result.friends.length > 0}
        {#each friendsList.result.friends as friend (friend.id)}
          <div
            class="flex w-full flex-row items-center gap-3 rounded-[20px] bg-chatbutton/25 p-2 text-center"
          >
            <div class="justify-start">
              <FullAvatar size={32}><TelegramAvatar twirlSize={16} userId={friend.id} /></FullAvatar
              >
            </div>
            <div class="line-clamp-1 text-ellipsis text-[16px]">
              {friend.name}
            </div>
            <div>
              <OnlineDotIcon online={friend.online} />
            </div>
            <div class="ml-auto flex flex-row gap-2">
              <div
                class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-chatbutton/50 text-white"
                on:click={() => unfriend(friend)}
              >
                <CrossIcon />
              </div>
            </div>
            <div
              class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-chatbutton/50 text-white"
              on:click={() => push(friend)}
            >
              <HandIcon />
            </div>
          </div>
        {/each}
      {:else}
        <div class="mb-6 flex h-full w-full flex-grow items-center justify-center text-[16px]">
          {$tr('friends_no_friends')}
        </div>
      {/if}
    {/await}
  </div>
</BottomSheet2>
