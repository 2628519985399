<script lang="ts">
  import { getAnglePoint, getRandomInt, range } from '$lib/utils'
  import { onMount } from 'svelte'

  export let emoji: string

  export let scaleMult: number

  let rosesCount: number
  let roses: HTMLElement[] = []

  onMount(async () => {
    rosesCount = getRandomInt(8, 15)
  })

  $: {
    if (roses.length >= rosesCount) {
      roses.forEach(async (el) => {
        const { width, height } = el.getBoundingClientRect()

        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        // initial position of flowers
        const startXMiddle = getRandomInt(0, windowWidth)

        const initAngle = getRandomInt(-45, 45)

        // explosion
        const randomCoords = getAnglePoint(getRandomInt(100, 250), initAngle)

        const randomX = startXMiddle + randomCoords.x

        const targetScale = 0.5 * scaleMult

        const move = el.animate(
          [
            {
              transform: `translate(${startXMiddle}px, ${-height}px) scale(0.2) rotate(${initAngle}deg)`,
              opacity: 1,
            },
            {
              transform: `translate(${randomX}px, ${windowHeight - height}px) scale(${targetScale}) rotate(${initAngle}deg)`,
              opacity: 1,
              offset: 0.5,
            },
            {
              transform: `translate(${randomX}px, ${windowHeight - height}px) scale(${targetScale}) rotate(${initAngle}deg)`,
              opacity: 1,
            },
          ],
          {
            fill: 'forwards',
            duration: 1000 + getRandomInt(-200, 200),
          }
        )

        const opacity = el.animate([{ opacity: 1 }, { opacity: 0 }], {
          fill: 'forwards',
          duration: 500,
          delay: 600,
          easing: 'ease-in',
        })

        const blur = el.animate([{ filter: 'blur(0)' }, { filter: 'blur(40px)' }], {
          fill: 'forwards',
          duration: 1000,
          delay: 600,
          easing: 'ease-in',
        })

        await Promise.all([move.finished, opacity.finished, blur.finished])

        queueMicrotask(() => el.remove())
      })
    }
  }
</script>

{#each range(rosesCount) as index}
  <div class="anim text-[120px]" bind:this={roses[index]}>
    {emoji}
  </div>
{/each}

<style>
  .anim {
    position: absolute;
    will-change: transform, opacity, filter;
    transform-origin: 50% 50%;
    filter: drop-shadow(0px 4px 35px rgba(255, 0, 77, 0.75));
  }
</style>
