<script lang="ts">
  export let size = 22
</script>

<svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="ton_symbol 1" clip-path="url(#clip0_2081_11687)">
    <path
      id="Vector"
      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92486 17.0751 0 11 0C4.92486 0 0 4.92486 0 11C0 17.0751 4.92486 22 11 22Z"
      fill="#0098EA"
    />
    <path
      id="Vector_2"
      d="M14.7558 6.13945H7.24368C5.86247 6.13945 4.98703 7.62936 5.68192 8.83382L10.3181 16.8696C10.6206 17.3943 11.3788 17.3943 11.6814 16.8696L16.3185 8.83382C17.0124 7.63129 16.137 6.13945 14.7567 6.13945H14.7558ZM10.3143 14.4598L9.30465 12.5057L6.86839 8.1484C6.70767 7.86951 6.90618 7.51213 7.24274 7.51213H10.3134V14.4608L10.3143 14.4598ZM15.1292 8.14746L12.6939 12.5066L11.6842 14.4598V7.51119H14.7548C15.0914 7.51119 15.2899 7.86857 15.1292 8.14746Z"
      fill="white"
    />
  </g>
  <defs>
    <clipPath id="clip0_2081_11687">
      <rect width="22" height="22" fill="white" />
    </clipPath>
  </defs>
</svg>
