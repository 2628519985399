<script lang="ts">
  import { apiCurrentUser } from '$lib/api'
  import CoinsAddIcon from '$lib/assets/CoinsAddIcon.svelte'
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'
  import BottomSheet2 from '$lib/components/BottomSheet2.svelte'
  import Button from '$lib/components/Button.svelte'
  import InvitedList from '$lib/components/Referrals/InvitedList.svelte'
  import { tr } from '$lib/i18n'
    import { createEventDispatcher } from 'svelte'
  import AccountCoins from './AccountCoins.svelte'
    import CrossIcon from '$lib/assets/CrossIcon.svelte'

  const eventDispatcher = createEventDispatcher()

  const numberFormat = new Intl.NumberFormat()

  $: coinsFormatted = numberFormat.format($apiCurrentUser?.coins ?? 0)

  let coinsSheetShow: boolean = false
</script>

<!-- 
<div class="flex w-full justify-end">
  {#if $apiCurrentUser}
    <UserAvatarButton userId={$apiCurrentUser.id} on:click={() => appState.set('account')} />
  {/if}
</div> -->

<BottomSheet2 class="relative h-[95vh] p-7 pb-4 pt-0" on:close>
  {#if $apiCurrentUser}
    <div class="absolute -top-6 self-center">
      <CoinsIcon glow size={111} />
    </div>
    <div class="right-5 top-3 absolute" on:click={() => (eventDispatcher('close'))}><CrossIcon/></div>
    <div class="mt-20 flex grow flex-col gap-3 pt-[24px] font-medium text-default">
      <div class="flex flex-col items-center gap-2">
        <div class="text-[28px]">{coinsFormatted}</div>

        <div class="flex items-center self-stretch text-grey">
          <div class="mt-[-10px] flex grow basis-0 items-center justify-center">
            <span class="text-[14px]">Coins</span>
          </div>
        </div>
      </div>
      <Button height={50} on:click={() => (coinsSheetShow = true)}>
        <div class="-translate-y-[2px]">{$tr('add_coins')}</div>
        <CoinsAddIcon size={16} /></Button
      >
      <div class="relative mt-2 overflow-y-scroll">
        <InvitedList />
      </div>
    </div>
  {/if}
</BottomSheet2>

  {#if coinsSheetShow}
    <AccountCoins on:close={() => (coinsSheetShow = false)} />
  {/if}
