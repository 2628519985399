<script lang="ts">
  import { api, apiCurrentUser } from '$lib/api'
  import Checkmark from '$lib/assets/Checkmark.svelte'
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'
  import RatingIcon from '$lib/assets/RatingIcon.svelte'
  import BottomSheet2 from '$lib/components/BottomSheet2.svelte'
  import FullAvatar from '$lib/components/FullAvatar.svelte'
  import TelegramAvatar from '$lib/components/TelegramAvatar.svelte'
  import type { ApiTypes } from '@twixer/core'
  import { createEventDispatcher, onMount } from 'svelte'
  import AccountCoins from './AccountCoins.svelte'
  import { tr } from '$lib/i18n'
  import UserStatus from '$lib/components/Call/UserStatus.svelte'
  import Button from '$lib/components/Button.svelte'
  import CoinFilledIcon from '$lib/assets/CoinFilledIcon.svelte'
  import Input from '$lib/components/Input.svelte'
  import BrushIcon from '$lib/assets/BrushIcon.svelte'
  import { analyticsTrack } from '$lib/analytics'
  import InviteButton from '$lib/components/Referrals/InviteReferralButton.svelte'
  import VipBuyPopup from '$lib/components/Shop/VipBuyPopup.svelte'

  let coinsSheetShow = false
  let editStatusSheetShow = false
  let vipPopupShow = false

  let selected: ApiTypes.ReactionProduct | null = null
  let status: string | undefined

  let reactionsPromise = $api.client.emitWithAck('shop:reactions:get-all')

  let statusError: string | null = null

  async function updateStatus() {
    if (!status) return

    const normalized = status.trimStart().trimEnd()

    if (!!$apiCurrentUser && $apiCurrentUser.cosmetics.status === normalized) {
      statusError = $tr('error').status_same
      return
    }

    const result = await $api.client.emitWithAck('cosmetics:update', {
      status: normalized,
      reactionId: $apiCurrentUser?.cosmetics.reaction?.id,
    })

    if (!result.ok) {
      statusError = $tr('error')[result.error.message]
      return
    }

    analyticsTrack('Status Bought', {
      status: normalized,
    })

    editStatusSheetShow = false
  }

  async function updateReaction(reaction: ApiTypes.ReactionProduct) {
    selected = reaction

    await $api.client.emitWithAck('cosmetics:update', {
      reactionId: reaction.id,
    })
  }

  async function buyReaction(reaction: ApiTypes.ReactionProduct) {
    if (reaction.bought) {
      return updateReaction(reaction)
    }

    const buyResult = await $api.client.emitWithAck('shop:reactions:buy', {
      reactionId: reaction.id,
    })

    if (buyResult.ok) {
      reaction.bought = true

      analyticsTrack('Reaction Bought', {
        emoji: reaction.emoji,
        price: reaction.price,
      })

      updateReaction(reaction)
    } else if (buyResult.error?.message === 'reaction_is_vip') {
      vipPopupShow = true
    } else {
      coinsSheetShow = true
    }
  }

  onMount(() => {
    status = $apiCurrentUser?.cosmetics.status ?? undefined
  })
</script>

<BottomSheet2 class="relative h-[75vh] overflow-y-scroll p-6" on:close>
  {#if $apiCurrentUser}
    <div class="relative flex w-full flex-col items-center">
      {#if !$apiCurrentUser.isVip}
        <div
          class="absolute -right-4 -top-4 h-auto rounded-[22px] bg-gradient-to-r from-[#A2A6ED] via-[#464A9B] to-[#464A9B] px-[1.5px] py-0.5"
        >
          <Button variant="vip" height={36} class="w-full" on:click={() => (vipPopupShow = true)}
            >{$tr('buy_vip')}</Button
          >
        </div>
      {/if}

      <div class="pointer-events-none fixed flex w-full -translate-y-2/3 transform justify-center">
        <div class="relative">
          {#if $apiCurrentUser.isVip}
            <div
              class="rounded-full bg-gradient-to-r from-[#A2A6ED] via-[#464A9B] to-[#464A9B] p-1"
            >
              <FullAvatar size={111}><TelegramAvatar twirlSize={64} /></FullAvatar>
            </div>

            <div class="absolute -right-5 bottom-0">
              <div
                class="h-auto w-14 rounded-[22px] bg-gradient-to-r from-[#A2A6ED] via-[#464A9B] to-[#464A9B] px-[1.5px] py-0.5"
              >
                <div
                  class="w-full rounded-[20px] bg-gradient-to-r from-[#626AEE] via-[#8A91FB] to-[#383D88] text-center"
                >
                  VIP
                </div>
              </div>
            </div>
          {:else}
            <FullAvatar size={111}><TelegramAvatar twirlSize={64} /></FullAvatar>
          {/if}
          <div class="pointer-events-auto absolute bottom-28 left-20 w-full">
            <UserStatus>
              <div
                class="flex flex-col"
                on:click={() => {
                  editStatusSheetShow = true
                }}
              >
                <div class="text-center text-[17px]">
                  {!!$apiCurrentUser.cosmetics.status
                    ? $apiCurrentUser.cosmetics.status
                    : $tr('change_status')}
                </div>
                <div class="flex justify-end gap-1 text-[12px]">
                  <span class="inline-block translate-y-0.5"><BrushIcon size={14} /></span>

                  {#if !$apiCurrentUser?.isVip}
                    <span class="-mr-0.5 inline-block translate-y-0.5"><CoinsIcon size={14} /></span
                    >
                    <div class="items-center text-coin">100</div>
                  {/if}
                </div>
              </div>
            </UserStatus>
          </div>
        </div>
      </div>

      <div class="mb-4 mt-12 flex flex-col gap-4 text-default">
        <div class="flex flex-row items-center justify-center gap-3 font-medium">
          <div class="text-2xl font-semibold">{$apiCurrentUser.name}</div>
          <div class="flex items-center gap-1 rounded-full bg-chatbutton/50 px-4 py-2">
            <span><RatingIcon fill={'#FF004D'} /></span>
            <span class="text-lg font-medium">{$apiCurrentUser.rating}</span>
          </div>
        </div>

        <InviteButton />
      </div>
    </div>

    <div class="-mb-6 flex select-none overflow-x-hidden overflow-y-scroll overscroll-none p-3">
      <div class="-mx-3 -mt-3 flex flex-grow flex-wrap justify-center gap-3">
        {#await reactionsPromise then reactions}
          {#if reactions.result}
            {#each reactions.result as reaction}
              <div
                class="flex h-[110px] w-[100px] flex-col items-center justify-center rounded-[20px] bg-chatbutton/50 p-4 text-center"
                on:click={() => buyReaction(reaction)}
                class:selected={selected === reaction}
                class:vip={reaction.isVip}
              >
                <div class="mb-2 text-[32px]">{reaction.emoji}</div>
                {#if selected === reaction}
                  <div class="flex h-7 w-7 justify-center rounded-full bg-shopbubble">
                    <div class="translate-x-0.5">
                      <Checkmark size={24} />
                    </div>
                  </div>
                {:else if reaction.bought}
                  <div
                    class="flex h-7 flex-row items-center justify-between rounded-2xl bg-shopbubble px-2 backdrop-blur-[10px]"
                  >
                    {$tr('bought')}
                  </div>
                {:else}
                  <div
                    class="flex h-7 flex-row items-center justify-between rounded-2xl bg-shopbubble px-2 backdrop-blur-[10px]"
                  >
                    <span class="inline-block"><CoinsIcon size={19} /></span>
                    <div class="items-center pl-2 text-coin">{reaction.price}</div>
                  </div>
                {/if}
              </div>
            {/each}
          {/if}
        {/await}
      </div>
    </div>
  {/if}
</BottomSheet2>

{#if vipPopupShow}
  <VipBuyPopup on:close={() => (vipPopupShow = false)} />
{/if}

{#if coinsSheetShow}
  <AccountCoins on:close={() => (coinsSheetShow = false)} />
{/if}

{#if editStatusSheetShow}
  <BottomSheet2 class="relative p-6" on:close={() => (editStatusSheetShow = false)}>
    <div class="mt-4 flex flex-col gap-4 text-default">
      <div class="text-center text-[24px] font-bold text-default">
        {$tr('update_status_header')}
      </div>

      <div class="flex flex-col items-center font-medium">
        <Input maxLength={16} bind:value={status} />
      </div>

      {#if statusError}
        <div class="text-center text-grey">{statusError}</div>
      {/if}

      <div class="h-4"></div>

      <div class="flex w-full items-center justify-center">
        <Button on:click={() => updateStatus()}>
          <div class="flex grow items-center">
            <div>{$tr('save')}</div>
            {#if !$apiCurrentUser?.isVip}
              <span class="ml-2">100</span><span class="ml-1 inline-block translate-y-[1px]"
                ><CoinFilledIcon size={19} /></span
              >
            {/if}
          </div>
        </Button>
      </div>
    </div>
  </BottomSheet2>
{/if}

<style lang="postcss">
  .vip {
    @apply bg-gradient-to-r from-[#A2A6ED] via-[#464A9B] to-[#464A9B];
  }
  .selected {
    box-shadow: inset 0px 0px 0px 2px var(--color-accent);
  }
</style>
