<script lang="ts">
  import Button from '../Button.svelte'
  import FullAvatar from '../FullAvatar.svelte'
  import TelegramAvatar from '../TelegramAvatar.svelte'
  import { fade, fly } from 'svelte/transition'
  import { appReceivedFriendRequest } from '$lib/app-service'
  import { api } from '$lib/api'
  import { tr } from '$lib/i18n'

  $: friendSenderId = $appReceivedFriendRequest?.senderId

  async function accept() {
    if (!friendSenderId) {
      // log error

      friendSenderId = undefined
      return
    }

    await $api.client.emitWithAck('user:accept-friend-request', {
      senderId: friendSenderId,
    })

    friendSenderId = undefined
  }

  async function decline() {
    if (!friendSenderId) {
      // log error

      friendSenderId = undefined
      return
    }

    await $api.client.emitWithAck('user:decline-friend-request', {
      senderId: friendSenderId,
    })

    friendSenderId = undefined
  }
</script>

{#if !!friendSenderId}
  <div
    transition:fade|global
    class="relative flex w-[80vw] select-none flex-col items-center justify-center rounded-[20px] bg-[#3E3E40]/50 p-2 backdrop-blur-md"
  >
    <div class="absolute -top-20">
      <FullAvatar><TelegramAvatar twirlSize={32} userId={friendSenderId} /></FullAvatar>
    </div>
    <div class="mt-8 flex justify-center p-4 text-center text-2xl font-bold">
      {$tr('friend_request_popup_header')}
    </div>
    <div class="flex w-full justify-between gap-3">
      <div class="basis-1/2">
        <Button class="w-full" variant="transparent" on:click={decline}>{$tr('decline')}</Button>
      </div>
      <div class="basis-1/2">
        <Button class="w-full" on:click={accept}>{$tr('accept')}</Button>
      </div>
    </div>
  </div>
{/if}
