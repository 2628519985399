<script lang="ts">
  import TelegramAvatar from '$lib/components/TelegramAvatar.svelte'

  export let userId: string
</script>

<div on:click>
  <div
    class="pointer-events-none flex h-10 w-10 shrink-0 select-none items-center justify-center rounded-full bg-white text-[17px] font-medium text-accent-dark"
  >
    <TelegramAvatar {userId} twirlSize={16} />
  </div>
</div>
