<script lang="ts">
  import { apiCurrentUser } from '$lib/api'
  import SignUp from '../SignUp.svelte'
  import Home from '../Home.svelte'
  import Match from '../Match.svelte'
  import { appService } from '$lib/app-service'
  import { onMount } from 'svelte'
  import { localStream } from '$lib/stream'
</script>

{#if $apiCurrentUser}
  {#if $apiCurrentUser.age === 'UNKNOWN' && $apiCurrentUser.isRegistered}
    <SignUp state={'age'} />
  {:else if $apiCurrentUser.isRegistered}
    <Home />
  {:else}
    <SignUp />
  {/if}
{/if}
