import BaseGift from '$lib/components/Gifts/BaseGift.svelte'
import { get, writable } from 'svelte/store'

export const giftContainer = writable<HTMLElement | null>(null)

export function getCenterCoords(el: HTMLElement) {
  const rect = el.getBoundingClientRect()

  return { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 }
}

export const baseGift = (emoji: string) => {
  let lastClick = Date.now()

  return {
    trigger({
      fromX,
      fromY,
      toX,
      toY,
    }: {
      fromX: number
      fromY: number
      toX: number
      toY: number
    }) {
      const elapsed = Date.now() - lastClick
      lastClick = Date.now()
      const scale = Math.min(3, (1 / elapsed) * 200)

      const container = get(giftContainer)
      if (!container) return

      new BaseGift({
        target: container,
        props: {
          emoji,
          scaleMult: Math.max(1, scale),
        },
      })
    },
  }
}
