<script lang="ts">
  import CoinFilledIcon from '$lib/assets/CoinFilledIcon.svelte'

  export let ratio: number

  $: smallCoin = ratio < 0.05
  $: bigCoin = !smallCoin
  $: coinSize = Math.min(18, 8 + 10 * ratio * 5)
</script>

<div>
  <div class="relative h-2 w-16 rounded-full bg-white/25">
    <div class="absolute inset-0 rounded-full bg-white" style:width="{ratio * 100}%">
      <div
        class="absolute right-0 top-1/2 -translate-y-1/2 transition-transform"
        class:small-coin={smallCoin}
        class:big-coin={bigCoin}
      >
        <CoinFilledIcon size={coinSize} />
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  .small-coin {
    @apply translate-x-full;
    @apply translate-x-1;
  }

  .big-coin {
    @apply translate-x-1/3;
  }
</style>
