import { derived } from 'svelte/store'
import { apiCurrentUser } from './api'
import type { ApiTypes } from '@twixer/core'

type Locales = 'en' | 'ru'
interface KeysTypes {
  start_video_chat: string
  your_gender: string
  guy: string
  girl: string
  cannot_change_gender_after_reg: string
  cannot_change_age_after_reg: string
  read_and_accept_terms: string
  signup_confirm_age: string
  confirm: string
  girls_and_guys: string
  girls_only: string
  guys_only: string
  chat_with_filter: string
  finding_someone: string
  report_user_confirm: string
  banner_know_first: string
  banner_join_community: string
  banner_evening_better: string
  banner_evening_description: string
  add_coins: string
  invite_home_button: string
  invite_friend_button: string
  invite_telegram_message: string
  referrals_landing: ReferralsLandingLocale
  balance: string
  add_coins_stars_header: string
  you_will_get_coins: string
  buy: string
  bought: string
  your_age: string
  age_13: string
  age_14_17: string
  age_18: string
  update_status_header: string
  edit: string
  save: string
  tap_here: string
  change_status: string
  friend_remove_confirm: string
  friends_list: string
  friends_no_friends: string
  accept: string
  decline: string
  friend_request_popup_header: string
  error: Errors
  call_direct_connecting: string
  friend_send_url: string
  friend_copy_url: string
  friends_invite: string
  rps_invite: string
  buy_vip: string
  buy_vip_long: string
  vip_free_status: string
  vip_emoji: string
  vip_timer: string
  vip_border: string
  vip_header_male: string
  vip_header_female: string
  gifts: string
}

export interface Errors {
  status_same: string
  status_invalid_length: string
  friend_unable_to_unfriend: string
  error: string
}

export interface ReferralsLandingLocale {
  header: string
  blocks: {
    title: string
    description: string
  }[]
}

const translations: { [K in keyof KeysTypes]: { [L in Locales]: KeysTypes[K] } } = {
  gifts: {
    en: 'Gifts',
    ru: 'Подарки',
  },
  vip_header_female: {
    ru: 'Стань королевой',
    en: 'Be the queen',
  },
  vip_header_male: {
    en: 'Be the king',
    ru: 'Стань королем',
  },
  vip_border: {
    ru: 'Выделись среди других',
    en: 'Highlight yourself among others',
  },
  vip_timer: {
    ru: 'Пропуск без ожидания 10 секунд',
    en: 'Skip without 10 seconds delay',
  },
  vip_emoji: {
    ru: 'Эксклюзивные реакции',
    en: 'Exclusive reactions',
  },
  vip_free_status: {
    ru: 'Бесплатная смена статуса',
    en: 'Free status change',
  },
  buy_vip_long: {
    en: 'Upgrade to VIP',
    ru: 'Купить VIP статус',
  },
  buy_vip: {
    en: 'Buy VIP',
    ru: 'Купить VIP',
  },
  rps_invite: {
    ru: 'Сыграем в камень, ножницы, бумагу? 🧱✂️📄',
    en: 'Wanna play rock-paper-scissors? 🧱✂️📄',
  },
  cannot_change_age_after_reg: {
    en: 'You cannot change your age after registration',
    ru: 'Ты не сможешь изменить возраст после регистрации',
  },
  friends_invite: {
    en: 'Invite friends',
    ru: 'Пригласить друзей',
  },
  friend_send_url: {
    en: 'Send a link',
    ru: 'Отправить ссылку',
  },
  friend_copy_url: {
    en: 'Copy link',
    ru: 'Копировать ссылку',
  },
  accept: {
    en: 'Accept',
    ru: 'Принять',
  },
  decline: {
    en: 'Decline',
    ru: 'Отклонить',
  },
  friend_request_popup_header: {
    en: 'Match wants to be friends',
    ru: 'Собеседник хочет дружить',
  },
  call_direct_connecting: {
    ru: 'Соединяем с собеседником...',
    en: 'Connecting with the contact...',
  },
  friends_no_friends: {
    en: 'No friends yet :(',
    ru: 'Пока что у тебя нет друзей :(',
  },
  friends_list: {
    en: 'Friends List',
    ru: 'Список друзей',
  },
  friend_remove_confirm: {
    en: 'Are you sure you want to remove this friend?',
    ru: 'Ты уверен, что хочешь удалить этого друга?',
  },
  change_status: {
    en: 'Change status',
    ru: 'Изменить статус',
  },
  tap_here: {
    en: 'Tap here',
    ru: 'Нажми',
  },
  save: {
    en: 'Save',
    ru: 'Сохранить',
  },
  edit: {
    en: 'Edit',
    ru: 'Изменить',
  },
  update_status_header: {
    en: 'Update status',
    ru: 'Обновить статус',
  },
  start_video_chat: {
    en: 'Start Video Chat',
    ru: 'Начать видеочат',
  },
  your_gender: {
    en: 'Your Gender',
    ru: 'Пол',
  },
  guy: {
    en: 'Guy',
    ru: 'Парень',
  },
  girl: {
    en: 'Girl',
    ru: 'Девушка',
  },
  cannot_change_gender_after_reg: {
    en: 'You cannot change your gender after registration',
    ru: 'Ты не сможешь изменить пол после регистрации',
  },
  read_and_accept_terms: {
    en: 'I have read and accept <a class="font-medium" href="https://telegra.ph/Terms-of-Service-02-15-3" target="_blank">Terms of Service</a> and <a class="font-medium" href="https://telegra.ph/Privacy-Policy-02-15-50" target="_blank">Privacy Policy</a>',
    ru: 'Я прочитал(а) и принимаю <a class="font-medium" href="https://telegra.ph/Terms-of-Service-02-15-3" target="_blank">Условия использования</a> и <a class="font-medium" href="https://telegra.ph/Privacy-Policy-02-15-50" target="_blank">Политику конфиденциальности</a>',
  },
  signup_confirm_age: {
    en: 'I confirm that I am 18 years of age or older',
    ru: 'Я подтверждаю, что мне уже есть 18 лет',
  },
  confirm: {
    en: 'Confirm',
    ru: 'Подтверждаю',
  },
  girls_and_guys: {
    en: 'Girls and Guys',
    ru: 'Девушки и Парни',
  },
  girls_only: {
    en: 'Girls Only',
    ru: 'Девушки',
  },
  guys_only: {
    en: 'Guys Only',
    ru: 'Парни',
  },
  chat_with_filter: {
    en: 'Chat with (Coming soon)',
    ru: 'Фильтр (скоро)',
  },
  finding_someone: {
    en: 'Finding Someone Cool',
    ru: 'Ищу кого-то классного',
  },
  report_user_confirm: {
    en: 'Are you sure you want to report this user?',
    ru: 'Ты точно хочешь пожаловаться на собеседника?',
  },
  banner_know_first: {
    en: 'Be the first to know about giveaways and updates',
    ru: 'Узнай первым о розыгрышах, обновлениях',
  },
  banner_join_community: {
    en: 'Join the community',
    ru: 'Вступить в сообщество',
  },
  banner_evening_better: {
    en: "It's more fun in the evening",
    ru: 'Вечером онлайн выше',
  },
  banner_evening_description: {
    en: 'Be online after 7:00 PM MSK<br/>to meet more friends!',
    ru: 'Приходите после 19:00 МСК чтобы<br />встретить больше друзей!',
  },
  add_coins: {
    en: 'Add coins',
    ru: 'Купить монеты',
  },
  you_will_get_coins: {
    en: 'You will get',
    ru: 'Вы получите',
  },
  add_coins_stars_header: {
    en: 'Set amount of Stars',
    ru: 'Введите количество Stars',
  },
  invite_home_button: {
    en: 'Invite',
    ru: 'Пригласить',
  },
  invite_friend_button: {
    en: 'Invite friend',
    ru: 'Позвать друга',
  },
  buy: {
    en: 'Buy',
    ru: 'Купить',
  },
  bought: {
    en: 'Bought',
    ru: 'Куплено',
  },
  your_age: {
    en: 'Age',
    ru: 'Возраст',
  },
  invite_telegram_message: {
    en: `\nHey! Let’s chat and earn new coins together - Twixers 💸🤝
Also, find more friends and meet new people 💘
    
Click the link 👆 and **get a starting bonus of 1000**💰`,
    ru: `\nПривет! Погнали общаться и вместе рубить за это новые монеты - Твиксы 💸🤝
    
А еще искать больше друзей и знакомиться с новыми людьми 💘
    
👆Нажимай на ссылку, и **получи стартовый бонус 1000**💰`,
  },
  referrals_landing: {
    ru: {
      header: 'Приглашай друзей, получай бонус',
      blocks: [
        {
          title: 'Получай +1000',
          description: 'За каждого друга',
        },
        {
          title: 'Твой друг также получит +1000',
          description: 'За то, что перешел по твоей ссылке!',
        },
      ],
    },
    en: {
      header: 'Invite friends, get bonus',
      blocks: [
        {
          title: 'Get +1000',
          description: 'For each friend',
        },
        {
          title: 'Your friend will also get +1000',
          description: 'For following your link!',
        },
      ],
    },
  },
  balance: {
    en: 'Balance',
    ru: 'Баланс',
  },
  age_13: {
    en: 'Under 13',
    ru: 'Менее 13',
  },
  age_14_17: {
    en: '14-17',
    ru: '14-17',
  },
  age_18: {
    en: '18+',
    ru: '18+',
  },
  error: {
    en: {
      status_same: 'You already have this status',
      status_invalid_length: 'Status length must be between 1 and 16',
      friend_unable_to_unfriend: 'Unable to unfriend this user',
      error: 'An error occurred',
    },
    ru: {
      status_same: 'У вас уже установлен этот статус',
      status_invalid_length: 'Длина статуса должна быть от 1 до 16 символов',
      friend_unable_to_unfriend: 'Не удалось удалить этого пользователя из друзей',
      error: 'Произошла ошибка',
    },
  },
} as const

function getTargetLocale(locale?: string | null): Locales {
  switch (locale) {
    case 'ru':
      return locale
    default:
      return 'en'
  }
}

export const locale = derived(apiCurrentUser, ($currentUser) => getTargetLocale($currentUser?.lang))

export const tr = derived(locale, ($locale) => (key: keyof KeysTypes): string | object => {
  return translations[key][$locale]
})

export const trGender = derived(tr, ($tr) => (gender: ApiTypes.UserGender) => {
  switch (gender) {
    case 'FEMALE':
      return $tr('girl')
    case 'MALE':
      return $tr('guy')
    case 'UNKNOWN':
      return 'unknown'
  }
})
