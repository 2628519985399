<script lang="ts">
  import { api } from '$lib/api'
  import { onMount } from 'svelte'
  import OnlineDotIcon from '$lib/components/ui/OnlineDotIcon.svelte'

  let online = 0
  let timer: any

  onMount(() => {
    refreshStats()
    timer = setInterval(refreshStats, 2000)

    return () => {
      clearInterval(timer)
    }
  })

  async function refreshStats() {
    const stats = await $api.client.emitWithAck('admin:get-stats')
    online = stats.online
  }
</script>

<div class="flex flex-col items-center rounded-[40px] bg-[#3E3E40]/50 px-2 py-1 text-[13px]">
  <div class="flex items-center gap-1 font-medium text-white">
    <OnlineDotIcon />
    {online} в сети сейчас
  </div>
</div>
