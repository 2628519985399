<script lang="ts">
  import { captureException } from '@sentry/sveltekit'

  export let videoTrack: MediaStreamTrack | null
  export let mirror: boolean

  let videoEl: HTMLVideoElement | null = null
  let mediaStream = new MediaStream()

  function clearTracks() {
    mediaStream.getTracks().forEach((track) => mediaStream.removeTrack(track))
  }

  $: {
    if (videoTrack) {
      if (mediaStream.getVideoTracks()[0] !== videoTrack) {
        clearTracks()
        mediaStream.addTrack(videoTrack)
        ensurePlaying()
      }
    } else {
      clearTracks()
    }
  }

  $: if (videoEl) {
    videoEl.srcObject = mediaStream
    ensurePlaying()
  }

  function ensurePlaying() {
    videoEl?.play().catch((e) => {
      console.error('LocalVideo set error', e)
      captureException(e)
    })
  }
</script>

<video
  class="h-full w-full object-cover"
  class:mirror
  bind:this={videoEl}
  autoplay
  playsinline
  muted
/>

<style>
  video.mirror {
    transform: scaleX(-1);
  }
</style>
