<script lang="ts">
  import { apiCurrentUser } from '$lib/api'
  import { ages, appCurrentMatch } from '$lib/app-service'
  import RoundGrayButton from '$lib/components/ui/RoundGrayButton.svelte'

  $: isModerator = $apiCurrentUser?.isModerator ?? false
</script>

{#if $appCurrentMatch && isModerator}
  <div class="absolute bottom-3 left-3 select-none">
    <RoundGrayButton
      >{ages.filter((age) => age.value === $appCurrentMatch.partner.age)[0].name}</RoundGrayButton
    >
  </div>
{/if}
