<script lang="ts">
  export let size: number
</script>

<svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M18 9.00049C18 13.9711 13.9706 18.0005 9 18.0005C4.02944 18.0005 0 13.9711 0 9.00049C0 4.02993 4.02944 0.000488281 9 0.000488281C13.9706 0.000488281 18 4.02993 18 9.00049Z"
    fill="#FFB31A"
  />
  <path
    d="M8.99961 3.60059L10.4279 7.03467L14.1353 7.33189L11.3107 9.7515L12.1736 13.3693L8.99961 11.4306L5.82557 13.3693L6.68854 9.7515L3.8639 7.33189L7.57129 7.03467L8.99961 3.60059Z"
    fill="white"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9 16.8755C13.3492 16.8755 16.875 13.3497 16.875 9.00049C16.875 4.65125 13.3492 1.12549 9 1.12549C4.65076 1.12549 1.125 4.65125 1.125 9.00049C1.125 13.3497 4.65076 16.8755 9 16.8755ZM9 18.0005C13.9706 18.0005 18 13.9711 18 9.00049C18 4.02993 13.9706 0.000488281 9 0.000488281C4.02944 0.000488281 0 4.02993 0 9.00049C0 13.9711 4.02944 18.0005 9 18.0005Z"
    fill="#CF8E0F"
  />
</svg>
