<script lang="ts">
  import { apiCurrentUser } from '$lib/api'
  import RatingIcon from '$lib/assets/RatingIcon.svelte'
  import FullAvatar from '$lib/components/FullAvatar.svelte'
  import type { ApiTypes } from '@twixer/core'
  import TelegramAvatar from '$lib/components/TelegramAvatar.svelte'
  import RatingReceivedNotification from '../RatingReceivedNotification.svelte'
  import UserStatus from './UserStatus.svelte'
  import BrushIcon from '$lib/assets/BrushIcon.svelte'
  import { tr } from '$lib/i18n'
  import Button from '../Button.svelte'

  export let user: ApiTypes.Partner | ApiTypes.CurrentUser

  $: currentIsModerator = $apiCurrentUser?.isModerator ?? false

  export let ratingEl: HTMLElement | undefined = undefined

  $: currentUser = user === $apiCurrentUser

  $: opponentHasStatus = !currentUser && !!user.cosmetics && !!user.cosmetics.status

  $: vip = user.isVip
</script>

<!-- todo: Refactor this component later, this one is doing too much stuff -->

<div
  class="relative flex h-[40px] max-w-[70%] items-center gap-2 rounded-[30px] bg-[#3E3E40]/50 p-1 pr-4 backdrop-blur-md"
  class:vip
  class:opponentHasStatus
>
  <div class="flex flex-col">
    {#if opponentHasStatus}
      <div class="absolute -top-5 left-5">
        <UserStatus>{user.cosmetics.status}</UserStatus>
      </div>
    {/if}

    <FullAvatar size={32}><TelegramAvatar twirlSize={16} userId={user.id} /></FullAvatar>
  </div>

  <div class="flex flex-col">
    {#if currentIsModerator && !currentUser}
      <div class="line-clamp-1 text-ellipsis text-[13px] font-medium text-white">
        {user.name}
      </div>
    {/if}

    {#if currentUser}
      <RatingReceivedNotification />
      <div
        class="absolute -right-5 bottom-0 flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[#3E3E40]/50 backdrop-blur-md"
      >
        <BrushIcon size={16} />
      </div>

      <div class="absolute -top-5 left-5">
        <UserStatus>{!!user.cosmetics.status ? user.cosmetics.status : $tr('tap_here')}</UserStatus>
      </div>
    {:else}
      <div
        class="relative line-clamp-1 flex items-center gap-[2px] text-ellipsis text-[13px] font-normal tracking-tight text-white opacity-70"
      >
        <div bind:this={ratingEl}>
          <RatingIcon />
        </div>
        <span>{user.rating}</span>
        {#if vip}
          <div
            class="ml-1 h-auto w-8 rounded-[22px] bg-gradient-to-r from-[#A2A6ED] via-[#464A9B] to-[#464A9B] px-[1px] py-0.5"
          >
            <div
              class="w-full rounded-[20px] bg-gradient-to-r from-[#626AEE] via-[#8A91FB] to-[#383D88] text-center text-[10px] font-bold"
            >
              VIP
            </div>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</div>

<style lang="postcss">
  .vip {
    @apply bg-gradient-to-r;
    @apply from-[#626AEE];
    @apply via-[#8A91FB];
    @apply to-[#383D88];
  }

  .opponentHasStatus {
    margin-top: 12px;
  }
</style>
