<script lang="ts">
  import {
    api,
    apiCallDescription,
    apiCallIceCandidates,
    apiCallStarted,
    apiCallRemoteStream,
  } from '$lib/api'
  import { localStream } from '$lib/stream'
  import { captureMessage } from '@sentry/sveltekit'
  import { onMount } from 'svelte'

  export let matchId: string

  let polite = true

  let makingOffer = false
  let ignoreOffer = false
  let isSettingRemoteAnswerPending = false

  let pc: RTCPeerConnection

  async function setDescription(description: RTCSessionDescriptionInit) {
    try {
      // An offer may come in while we are busy processing SRD(answer).
      // In this case, we will be in "stable" by the time the offer is processed
      // so it is safe to chain it on our Operations Chain now.
      const readyForOffer =
        !makingOffer && (pc.signalingState == 'stable' || isSettingRemoteAnswerPending)
      const offerCollision = description.type == 'offer' && !readyForOffer

      ignoreOffer = !polite && offerCollision
      if (ignoreOffer) {
        return
      }

      isSettingRemoteAnswerPending = description.type == 'answer'
      await pc.setRemoteDescription(description) // SRD rolls back as needed
      isSettingRemoteAnswerPending = false
      if (description.type == 'offer') {
        await pc.setLocalDescription()
        $api.client.emit('call:description', {
          to: matchId,
          desc: pc.localDescription!,
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  async function addIceCandidate(candidate: RTCIceCandidateInit | null) {
    try {
      try {
        await pc.addIceCandidate(candidate ?? undefined)
      } catch (err) {
        if (!ignoreOffer) {
          throw err
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  onMount(() => {
    console.log('<Call> created', polite)
    $api.client.emit('call:join', matchId)

    return () => {
      if (pc) pc.close()
    }
  })

  function createPeerConnection() {
    console.warn('createPeerConnection', !!pc)

    if (pc) {
      console.log('close old PC')
      pc.onnegotiationneeded = null
      pc.oniceconnectionstatechange = null
      pc.ontrack = null
      pc.close()
    }

    pc = new RTCPeerConnection({
      iceServers: [
        { urls: 'stun:stun.twixer.bot:443' },
        { urls: 'turn:turn.twixer.bot:443?transport=udp', username: 'twixer', credential: 'twix' },
        { urls: 'turn:turn.twixer.bot:443?transport=tcp', username: 'twixer', credential: 'twix' },
        { urls: 'stun:stun.l.google.com:19302' },
      ],
    })

    makingOffer = false
    ignoreOffer = false

    pc.onsignalingstatechange = () => console.log('pc.signalingState', pc.signalingState)
    pc.onconnectionstatechange = () => console.log('pc.connectionState', pc.connectionState)

    pc.onicecandidate = ({ candidate }) =>
      $api.client.emit('call:iceCandidate', { to: matchId, candidate })

    pc.oniceconnectionstatechange = () => {
      console.log('pc.iceConnectionState', pc.iceConnectionState)
      if (pc.iceConnectionState === 'failed') {
        console.warn('ice restart called')
        pc.restartIce()
      }
    }

    pc.onnegotiationneeded = async () => {
      console.log('PC onnegotiationneeded')
      try {
        makingOffer = true
        await pc.setLocalDescription()
        $api.client.emit('call:description', {
          to: matchId,
          desc: pc.localDescription!,
        })
      } catch (err) {
        console.error(err)
      } finally {
        makingOffer = false
      }
    }

    pc.ontrack = ({ track, streams }) => {
      console.log('PC ontrack', track, streams)
      $apiCallRemoteStream = streams[0]
      // track.onunmute = () => {
      //   console.log('PC track onunmute')
      //   remoteVideoEl.srcObject = streams[0]
      // }
    }

    if ($localStream) {
      const mediaStream = new MediaStream([$localStream.audioTrack, $localStream.videoTrack])
      pc.addTrack($localStream.audioTrack, mediaStream)
      pc.addTrack($localStream.videoTrack, mediaStream)
    } else {
      const message = 'no local stream to add when creating RTCPeerConnection'
      captureMessage(message, 'warning')
      console.warn(message)
    }
  }

  $: {
    if ($localStream && pc) {
      console.log('replace track on peer connection')
      pc.getSenders()
        .find((sender) => sender.track?.kind === $localStream.videoTrack.kind)
        ?.replaceTrack($localStream.videoTrack)
    }
  }

  $: {
    console.warn('$apiCallStarted', $apiCallStarted)
    if ($apiCallStarted) {
      polite = $apiCallStarted.impolitePeerId !== $api.client.id
      // $apiCallDescription = null
      // $apiCallIceCandidates = []
      if (!pc) {
        createPeerConnection()
      } else {
        pc.restartIce()
      }
    }
  }

  $: if (pc && $apiCallDescription) {
    setDescription($apiCallDescription)
  }

  $: if (pc && $apiCallIceCandidates.length > 0) {
    console.log('apiCallIceCandidates', $apiCallIceCandidates.length, $apiCallIceCandidates)
    for (const cand of $apiCallIceCandidates) {
      addIceCandidate(cand)
    }
  }
</script>
