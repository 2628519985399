<script lang="ts">
  import { api, apiCurrentUser } from '$lib/api'
  import CoinsAddIcon from '$lib/assets/CoinsAddIcon.svelte'
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'
  import { tr, type ReferralsLandingLocale } from '$lib/i18n'
  import { openTelegramForward } from '$lib/utils'
  import { onMount } from 'svelte'
  import Button from '../Button.svelte'
  import { Payload, type ApiTypes } from '@twixer/core'
  import TelegramAvatar from '../TelegramAvatar.svelte'
  import { isiOs } from '$lib/stores'
  import InviteButton from './InviteReferralButton.svelte'

  const numberFormat = new Intl.NumberFormat()

  let friends: ApiTypes.Referral[] | undefined
  onMount(async () => {
    friends = await $api.client.emitWithAck('user:get-referrals')
  })

  $: haveFriends = true && !!friends && friends.length > 0

  function getMedal(index: number) {
    const medals: {
      [index: number]: string
    } = {
      1: '🥇',
      2: '🥈',
      3: '🥉',
    }

    return medals[index] ?? index + ''
  }

  const landing = $tr('referrals_landing') as ReferralsLandingLocale
</script>

<div class="relative flex w-full flex-col rounded-[20px] bg-bg-secondary px-4 py-2 text-default">
  <div class="mb-4 mt-2 w-auto select-none text-center text-[24px] font-bold leading-6">
    {landing.header}
  </div>

  {#if !haveFriends}
    <div class="flex flex-grow select-none flex-col">
      <ul class="flex-grow px-4">
        {#each landing.blocks as block}
          <li>
            <div class="flex flex-col text-[17px] leading-4">
              <div class="flex flex-row justify-start">
                <span class="w-[170px] font-semibold">
                  {block.title}
                  <span class="inline-block translate-y-1"><CoinsIcon /></span>
                </span>
              </div>
              <div class="mt-1 text-[16px] font-normal text-grey">
                {block.description}
              </div>
            </div>
          </li>
        {/each}
      </ul>
      <div class="mb-2 mt-auto flex grow items-center justify-center">
        <InviteButton />
      </div>
    </div>
  {:else if friends}
    <div
      class="flex max-h-[300px] min-h-[300px] flex-shrink flex-col overflow-x-hidden overflow-y-scroll px-4 pb-32"
    >
      {#each friends as friend, index}
        <div class="mb-4 flex flex-row items-center gap-4">
          <div class="flex w-4 items-center justify-center text-[18px]">
            {getMedal(index + 1)}
          </div>
          <div
            class="pointer-events-none flex h-10 w-10 shrink-0 select-none items-center justify-center rounded-full bg-[#F2F2F5]/10"
          >
            <div class="text-center"><TelegramAvatar userId={friend.id} twirlSize={16} /></div>
          </div>
          <div class="flex flex-col">
            <div class="whitespace-normal">
              {friend.name}
              <div class="text-[13px] text-coin">
                +{numberFormat.format(friend.isPremium ? 3000 : 1000)}
                <span class="inline-block translate-y-0.5"><CoinsIcon size={12} /></span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-x-0 bottom-20 z-10 flex w-full flex-grow flex-col items-center justify-center px-4"
        >
          <InviteButton />
        </div>
      {/each}
    </div>
  {/if}
</div>

<style lang="postcss">
  .haveFriends {
    @apply absolute;
    @apply w-[280px];
    @apply top-[248px];
    @apply ml-2;
    @apply left-0;
    @apply right-0;
    @apply bottom-0;
  }

  ul {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
    list-style-type: none;
  }

  ul li {
    padding: 0 0 1rem 2rem;
    position: relative;
    margin: 0;
  }

  ul li:after {
    position: absolute;
    top: 5px;
    left: 0;
    content: ' ';
    border-radius: 50%;
    display: inline-block;
    height: 16px;
    width: 16px;
    text-align: center;
    line-height: 24px;
    background: white;
  }

  ul li:before {
    position: absolute;
    left: 6.5px;
    top: 5px;
    content: '';
    height: 100%;
    width: 0;
    border-left: 3px solid white;
  }

  ul li:last-of-type:before {
    border: none;
  }
</style>
