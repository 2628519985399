<script lang="ts">
  export let mediaStream: MediaStream | null

  let videoEl: HTMLVideoElement

  // Android autoplay fix
  $: if (videoEl) videoEl.play().catch((e) => console.warn('RemoteVideo play error', e))

  $: if (mediaStream && videoEl && videoEl.srcObject !== mediaStream) {
    console.log('RemoteVideo set')
    videoEl.srcObject = mediaStream
    if (mediaStream !== null) {
      videoEl.play().catch((e) => console.error('RemoteVideo set error', e))
    }
  }
</script>

<video class="h-full w-full object-cover" bind:this={videoEl} autoplay playsinline />
