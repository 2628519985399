<script lang="ts">
  import { api, apiCurrentUser } from '$lib/api'
  import BottomSheet2 from '$lib/components/BottomSheet2.svelte'
  import FullAvatar from '$lib/components/FullAvatar.svelte'
  import TelegramAvatar from '$lib/components/TelegramAvatar.svelte'
  import ReportButton from '$lib/components/Call/ReportButton.svelte'
  import { analyticsTrack } from '$lib/analytics'
  import { tr } from '$lib/i18n'
  import type { ApiTypes } from '@twixer/core'
  import BanButton from './BanButton.svelte'

  $: isModerator = $apiCurrentUser?.isModerator ?? false

  $: matchHistoryPromise = getMatchHistory()

  function report(match: ApiTypes.MatchInfo) {
    Telegram.WebApp.showConfirm($tr('report_user_confirm'), (okPressed) => {
      if (okPressed) {
        analyticsTrack('ModMenu Report Sent', {
          ModeratorId: $apiCurrentUser?.id,
          ReportedUserId: match.partner.id,
          MatchId: match.matchId,
        })
        $api.client.emit('user:report', {
          reportToUser: match.partner.id,
          matchId: match.matchId,
        })

        match.partner.banned = true
      }
    })
  }

  async function getMatchHistory() {
    const matches = await $api.client.emitWithAck('user:get-match-history')

    return matches.result!
  }

  function getDuration(start: Date, end: Date) {
    if (!start || !end) return '0m 0s'

    // prisma moment
    const diff = Math.abs(new Date(end).getTime() - new Date(start).getTime())

    const hours = Math.floor(diff / (1000 * 60 * 60))
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((diff % (1000 * 60)) / 1000)

    return `${minutes + hours * 60}m ${seconds}s`
  }
</script>

<BottomSheet2 class="relative h-[55vh] overflow-y-scroll text-default" on:close>
  {#if isModerator}
    <div class="flex w-full flex-col items-center">
      <div class="mb-4 mt-6 flex flex-col gap-4">
        <div class="flex flex-row items-center justify-center gap-3 font-medium">
          <div class="text-2xl font-semibold">Match history</div>
        </div>
      </div>
    </div>

    <div class="flex select-none flex-col justify-between gap-3 overflow-y-scroll p-3">
      {#await matchHistoryPromise then matchHistory}
        {#each matchHistory as match (match.matchId)}
          <div
            class="flex w-full flex-row items-center gap-3 rounded-[20px] bg-chatbutton/25 p-2 text-center"
            class:banned={match.partner.banned}
          >
            <div class="justify-start">
              <FullAvatar size={32}
                ><TelegramAvatar twirlSize={16} userId={match.partner.id} /></FullAvatar
              >
            </div>
            <div class="line-clamp-1 text-ellipsis text-[16px]">
              {match.partner.name}
            </div>
            <div class="ml-auto text-ellipsis text-[16px]">
              ⏰ {getDuration(match.startedAt, match.endedAt ?? new Date())}
            </div>
            <div>
              <BanButton on:click={() => report(match)} />
            </div>
          </div>
        {/each}
      {/await}
    </div>
  {:else}
    <div class="flex h-full items-center justify-center text-center">
      <div class="w-[300px]">
        <div class="select-none text-6xl">🤯</div>
      </div>
    </div>
  {/if}
</BottomSheet2>

<style lang="postcss">
  .banned {
    @apply bg-[red]/20;
  }
</style>
