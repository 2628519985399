<script lang="ts" context="module">
  let zIndex = 50
</script>

<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import { fade, slide } from 'svelte/transition'

  export let padding: number
  export let backdrop = true
  export let hidden = false

  const dispatch = createEventDispatcher()

  onMount(() => {
    zIndex++
    return () => {
      zIndex--
    }
  })

  $: hasTop = !!$$slots.top
  $: topClass = hasTop ? 'pt-[88px]' : ''

  $: hiddenClass = hidden ? 'hidden' : ''
</script>

{#if backdrop}
  <div
    class="fixed bottom-0 left-0 right-0 top-0 bg-gradient-to-t from-black/50 to-black/0"
    transition:fade|global
    style:z-index={zIndex}
    on:click={() => dispatch('clickOutside')}
  ></div>
{/if}

<div
  class={`absolute bottom-0 left-0 right-0 ${hiddenClass} ${$$props.class ?? ''}`}
  style:z-index={zIndex}
  transition:slide
>
  {#if hasTop}
    <div class="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2">
      <slot name="top" />
    </div>
  {/if}

  <div
    class={`bg-bg rounded-t-[20px] ${topClass} h-full overflow-y-auto overscroll-y-auto`}
    style:padding={`${padding}px`}
  >
    <slot />
  </div>
</div>
