<script lang="ts">
  export let fill: string = 'white'
</script>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Like icon">
    <path
      id="Vector"
      d="M4.7389 2.93197C3.86829 3.03751 3.08986 3.42879 2.48043 4.07234C1.85564 4.72618 1.50483 5.5525 1.45874 6.47406C1.36912 8.19105 2.73137 10.1397 5.45588 12.1991C6.33161 12.8632 7.8347 13.8157 8.00114 13.8182C8.1727 13.8182 9.59129 12.9276 10.5131 12.2403C12.8382 10.5052 14.1902 8.81915 14.5 7.26177C14.5666 6.92197 14.5589 6.27327 14.4847 5.91546C14.2926 5.0042 13.7933 4.20362 13.0814 3.66562C11.6014 2.54842 9.54264 2.68227 8.24184 3.97709L8.00626 4.21135L7.7502 3.95907C7.20991 3.42364 6.55695 3.09157 5.78876 2.95514C5.56599 2.91653 4.97704 2.90366 4.7389 2.93197Z"
      {fill}
    />
  </g>
</svg>
