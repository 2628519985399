<script lang="ts">
  import { analyticsTrack } from '$lib/analytics'
  import { api, apiCurrentUser } from '$lib/api'
  import TwixerLogo from '$lib/assets/TwixerLogo.svelte'
  import Select from '$lib/components/Select.svelte'
  import StartVideoChatButton from '$lib/components/StartVideoChatButton.svelte'
  import Terms from '../lib/components/SignUp/Terms.svelte'
  import { tr } from '$lib/i18n'
  import BottomSheet2 from '$lib/components/BottomSheet2.svelte'
  import { onMount } from 'svelte'

  let name = $apiCurrentUser?.name ?? ''
  // let nameEl: Input
  let gender: (typeof genders)[0] | null = null
  let age: (typeof ages)[0] | null = null
  export let state: 'start' | 'gender' | 'terms' | 'age' = 'start'

  onMount(() => {
    if ($apiCurrentUser) {
      gender = genders.filter((g) => g.value === $apiCurrentUser.gender)[0] ?? null
    }
  })

  $: genders = [
    { icon: '👨', name: $tr('guy'), value: 'MALE' as const },
    { icon: '👩‍🦰', name: $tr('girl'), value: 'FEMALE' as const },
  ]

  $: ages = [
    { icon: '🌸', name: $tr('age_13'), value: 'JUNIOR' as const },
    { icon: '⚡️', name: $tr('age_14_17'), value: 'TEEN' as const },
    { icon: '👌', name: $tr('age_18'), value: 'ADULT' as const },
  ]

  function onGender(selectedGender: (typeof genders)[0]) {
    state = 'start'
    gender = selectedGender

    analyticsTrack('Register Gender Selected')
  }

  function onAge(selectedAge: (typeof ages)[0]) {
    state = 'start'
    age = selectedAge

    analyticsTrack('Register Age Selected')
  }

  async function signup() {
    if (gender && age) {
      analyticsTrack('Register Confirm Button Tapped')
      await $api.client.emit('user:signup', { name, gender: gender.value, age: age.value })
    }
  }

  async function start() {
    if (!gender) {
      state = 'gender'
    } else if (!age) {
      state = 'age'
    } else if ($apiCurrentUser?.isRegistered) {
      await signup()
    } else {
      state = 'terms'
    }

    analyticsTrack('Register StartVC Button Tapped')
  }
</script>

<div class="flex h-full flex-col">
  <div class="flex grow items-center justify-center">
    <TwixerLogo />
  </div>

  <div class="flex flex-col gap-6 p-8">
    {#if state === 'terms'}
      <Terms on:click={() => signup()} />
    {:else}
      <div class="flex flex-col gap-2">
        {#if !$apiCurrentUser?.isRegistered}
          <!-- <Input bind:this={nameEl} placeholder="Your Name" bind:value={name} /> -->
          <Select
            value={gender ? gender.name + ' ' + gender.icon : $tr('your_gender')}
            on:click={() => (state = 'gender')}
          />
        {/if}
        <Select
          value={age ? age.name + ' ' + age.icon : $tr('your_age')}
          on:click={() => (state = 'age')}
        />
      </div>

      <StartVideoChatButton on:click={start} />
    {/if}
  </div>
</div>

{#if state === 'gender'}
  <BottomSheet2 class="p-6" on:close={() => (state = 'start')}>
    <div class="flex flex-col gap-6">
      <div class="text-center text-2xl font-extrabold">{$tr('your_gender')}</div>
      <div class="flex gap-2">
        {#each genders as gender}
          <button
            class="flex grow flex-col items-center justify-center gap-2 rounded-[20px] bg-bg-secondary px-4 py-3 text-default"
            on:click={() => onGender(gender)}
          >
            <div class="text-[44px] leading-none">{gender.icon}</div>
            <div class="text-[17px] font-semibold">{gender.name}</div>
          </button>
        {/each}
      </div>

      <div class="text-center text-[13px] font-medium text-grey">
        {$tr('cannot_change_gender_after_reg')}
      </div>
    </div>
  </BottomSheet2>
{/if}

{#if state === 'age'}
  <BottomSheet2 class="p-6" on:close={() => (state = 'start')}>
    <div class="flex flex-col gap-6">
      <div class="text-center text-2xl font-extrabold">{$tr('your_age')}</div>
      <div class="flex gap-2">
        {#each ages as age}
          <button
            class="flex grow flex-col items-center justify-center gap-2 rounded-[20px] bg-bg-secondary px-4 py-3 text-default"
            on:click={() => onAge(age)}
          >
            <div class="text-[44px] leading-none">{age.icon}</div>
            <div class="text-[17px] font-semibold">{age.name}</div>
          </button>
        {/each}
      </div>

      <div class="text-center text-[13px] font-medium text-grey">
        {$tr('cannot_change_age_after_reg')}
      </div>
    </div>
  </BottomSheet2>
{/if}
