<div class="max-w-[170px] whitespace-nowrap status-bubble text-[12px] rounded-lg bg-bg px-2 py-0.5 inline-flex items-center select-none leading-relaxed flex-grow line-clamp-1">
  <slot/>
</div>

<style>
  .status-bubble {
    position: relative;
  }

  .status-bubble::before {
    position: absolute;
    content: '';
    background-color: rgb(var(--color-bg));
    width: 9px;
    height: 7px;
    top: 98%;
    left: 10%;
    border-radius: 10px; 
  }
</style>
