<script lang="ts" context="module">
  import { tv, type VariantProps } from 'tailwind-variants'

  const buttonVariants = tv({
    base: 'flex items-center justify-center gap-3 rounded-[20px] px-4 py-0 text-[17px] font-medium disabled:bg-grey',
    variants: {
      variant: {
        white: 'bg-white text-accent-dark active:brightness-90',
        accent: 'bg-accent text-white active:bg-accent-light',
        secondary: 'bg-bg-secondary text-default active:brightness-90',
        coin: 'bg-coin text-white active:brightness-90',
        accept: 'bg-[#198754] active:brightness-90',
        decline: 'bg-[#dc3545] active:brightness-90',
        transparent:
          'bg-transparent text-default backdrop-blur-md active:bg-chatbutton/5 active:brightness-90',
        vip: 'bg-gradient-to-r from-[#626AEE] via-[#8A91FB] to-[#383D88] px-2 text-white active:brightness-90',
      },
    },
  })
</script>

<script lang="ts">
  export let height = 52
  export let variant: VariantProps<typeof buttonVariants>['variant'] = 'accent'
  export let disabled = false
  let classes = ''
  export { classes as class }

  $: buttonClass = buttonVariants({ variant: variant, class: classes })
</script>

<button class={buttonClass} style:height={`${height}px`} on:click {disabled}>
  <slot name="icon" />
  <slot />
</button>
