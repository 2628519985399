<script lang="ts">
  import { currentUserRating } from '$lib/api'
  import HeartIcon from '$lib/assets/HeartIcon.svelte'
  import { tweened } from 'svelte/motion'
  import { fade, type TransitionConfig } from 'svelte/transition'
  import { backOut } from 'svelte/easing'
  import { onMount, tick } from 'svelte'
  import { derived } from 'svelte/store'
  import { flyingHeart, flyingHeartsContainer } from '$lib/actions/flying-heart'
  import { appCurrentMatch } from '$lib/app-service'

  let ratingTweened = tweened($currentUserRating, { duration: 500 })
  let ratingInt = derived(ratingTweened, (r) => Math.round(r))

  let show = false
  let animating = false

  let heartEl: HTMLElement
  const heart = flyingHeart()

  onMount(() => {
    return currentUserRating.subscribe(async (rating) => {
      // do not show on initialization
      if (rating === $ratingInt) return
      if (!$appCurrentMatch) return

      show = true
      await tick() // show

      if ($flyingHeartsContainer) {
        const rectContainer = $flyingHeartsContainer.getBoundingClientRect()
        const rectTarget = heartEl.getBoundingClientRect()
        heart.trigger({
          fromX: rectContainer.right - 50,
          fromY: rectContainer.top + 50,
          toX: rectTarget.left + 24 / 2,
          toY: rectTarget.top + 24 / 2,
          reaction: $appCurrentMatch.partner.cosmetics.reaction?.emoji ?? null,
        })
      }

      animating = true
      ratingTweened.set(rating).then(async () => {
        animating = false
        // hide after animation stopped
        await tick()
        show = false
      })
    })
  })

  function inTransition(node: HTMLElement): TransitionConfig {
    const existingTransform = getComputedStyle(node).transform.replace('none', '')
    return {
      delay: 0,
      duration: 400,
      easing: backOut,
      css: (t, u) => `opacity: ${t}; transform: ${existingTransform} scale(${t})`,
    }
  }
</script>

<div class="" bind:this={heartEl}>
  <div
    class="rating-container flex items-center gap-2"
    in:inTransition
    out:fade={{ delay: 500, duration: 1000 }}
  >
    <div>
      <div class="will-change-transform" class:anim={animating}>
        <HeartIcon width={24} height={24} />
      </div>
    </div>
    <span class="text-[15px] font-medium text-white">{$ratingInt}</span>
  </div>
</div>

<style lang="postcss">
  .rating-container {
    will-change: transform, opacity;
  }

  .anim {
    animation: heartbeat 300ms infinite linear;
    transform-origin: center center;
  }

  @keyframes heartbeat {
    0%,
    100% {
      transform: rotate(-15deg);
    }
    50% {
      transform: rotate(15deg);
    }
  }
</style>
