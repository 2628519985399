<script lang="ts">
  import RoundGrayButton from './ui/RoundGrayButton.svelte'
  import { onDestroy, onMount } from 'svelte'
  import { apiCurrentUser } from '$lib/api'
  import { appCallAt, appCurrentMatch, appService } from '$lib/app-service'
  import { analyticsTrack } from '$lib/analytics'
  import { secsToNow } from '$lib/utils'

  $: isModerator = $apiCurrentUser?.isModerator ?? false
  $: isVip = $apiCurrentUser?.isVip

  const enableNextButtonDelay = 10000

  let nextButtonEnablesAt: Date | null
  let enableNextButtonTimer: NodeJS.Timeout

  $: nextButtonEnablesInSeconds =
    nextButtonEnablesAt && nextButtonEnablesAt.getTime() - currentTime.getTime() >= 0
      ? Math.floor((nextButtonEnablesAt - currentTime) / 1000)
      : 0

  let currentTime = new Date()

  $: callStarted = !!$appCurrentMatch

  $: if (callStarted) {
    nextButtonEnablesAt = new Date()
    nextButtonEnablesAt.setSeconds(nextButtonEnablesAt.getSeconds() + enableNextButtonDelay / 1000)
  } else {
    nextButtonEnablesAt = null
  }

  onMount(() => {
    enableNextButtonTimer = setInterval(() => {
      currentTime = new Date()
    }, 1000)
  })

  onDestroy(async () => {
    clearInterval(enableNextButtonTimer)
  })

  function next() {
    analyticsTrack('VideoChat Next Button Tapped', { TimeToChat: secsToNow($appCallAt) })
    appService.nextMatch()
  }
</script>

{#if nextButtonEnablesInSeconds <= 0 || isModerator || isVip}
  <RoundGrayButton on:click={next}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M4.42409 18.581L12 11V17.5762C12 18.8421 13.5295 19.4761 14.4241 18.581L22 11L14.4241 3.41903C13.5295 2.52389 12 3.15787 12 4.4238V11L4.42409 3.41903C3.52954 2.52389 2 3.15787 2 4.4238V17.5762C2 18.8421 3.52954 19.4761 4.42409 18.581Z"
        fill="white"
      />
    </svg>
  </RoundGrayButton>
{:else}
  <RoundGrayButton>
    <div class="mb-0.5 select-none text-[20px] font-thin">
      {nextButtonEnablesInSeconds}
    </div>
  </RoundGrayButton>
{/if}
