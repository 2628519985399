<script lang="ts">
  import { tr } from '$lib/i18n'
  import { Payload } from '@twixer/core'
  import BottomSheet2 from '../BottomSheet2.svelte'
  import Button from '../Button.svelte'
  import { apiCurrentUser } from '$lib/api'
  import { isiOs } from '$lib/stores'
  import { openTelegramForward, telegramForwardUrl } from '$lib/utils'
  import { createEventDispatcher } from 'svelte'
  import { PUBLIC_BOT_URL } from '$env/static/public'

  const eventDispatcher = createEventDispatcher()

  $: message = $tr('invite_telegram_message') as string

  function openUrl() {
    if ($isiOs) message = message.replace(/\*/g, '')

    openTelegramForward(message, getInvitePayload())

    eventDispatcher('close')
  }

  function copyUrl() {
    if ($isiOs) message = message.replace(/\*/g, '')

    navigator.clipboard.writeText(getInviteUrl())

    eventDispatcher('close')
  }

  function getInviteUrl() {
    const url = new URL(PUBLIC_BOT_URL)
    url.searchParams.set('start', getInvitePayload())
    return url.toString()
  }

  function getInvitePayload() {
    return `${Payload.Referral}${$apiCurrentUser?.id}`
  }
</script>

<BottomSheet2 class="relative overflow-y-scroll bg-[#3E3E40]/50 backdrop-blur-lg" on:close>
  <div class="flex w-full flex-col items-center">
    <div class="mb-4 mt-6 flex flex-col gap-4">
      <div class="flex flex-row items-center justify-center gap-3 font-normal">
        <div class="text-2xl font-semibold">{$tr('friends_invite')}</div>
      </div>
    </div>
    <div class="flex w-full flex-col gap-4 p-4">
      <Button class="w-full" on:click={openUrl}>{$tr('friend_send_url')}</Button>
      <Button variant={'white'} on:click={copyUrl}>{$tr('friend_copy_url')}</Button>
    </div>
  </div>
</BottomSheet2>
