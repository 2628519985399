<script lang="ts">
  import { apiCurrentUser, api } from '$lib/api'
  import { appCurrentMatch, ages } from '$lib/app-service'
  import RoundGrayButton from '$lib/components/ui/RoundGrayButton.svelte'

  $: isModerator = $apiCurrentUser?.isModerator ?? false

  function changeAge(age: (typeof ages)[0]) {
    if (!isModerator || !$appCurrentMatch) return

    $api.client.emit('user:update', {
      userId: $appCurrentMatch.partner.id,
      data: {
        age: age.value,
      },
    })
  }
</script>

{#if isModerator && $appCurrentMatch}
  <div
    class="pointer-events-none absolute -top-5 left-3 z-10 flex h-full select-none flex-col justify-center"
  >
    <div class="pointer-events-auto flex flex-col gap-2">
      {#each ages as age}
        <RoundGrayButton on:click={() => changeAge(age)}>{age.name}</RoundGrayButton>
      {/each}
    </div>
  </div>
{/if}
