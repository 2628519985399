import { browser } from '$app/environment'
import { get, readable, writable } from 'svelte/store'

export const isiOs = readable(browser ? ['ios', 'macos'].includes(Telegram.WebApp.platform) : false)

type AvatarMap = {
  [userId: string]: string | null | undefined
}

// userId: avatar
export const avatarCache = writable<AvatarMap>({})

// todo: make this one better ;w;
export const starsInput = writable<number>(0)

export const openedShopToday = writable<boolean>(false)
