<script lang="ts">
  import { apiCurrentUser } from '$lib/api'
  import { appState } from '$lib/app-service'
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'
  import RatingIcon from '$lib/assets/RatingIcon.svelte'
  import TonSymbol from '$lib/assets/TonSymbol.svelte'
  import TonSymbolCircle from '$lib/assets/TonSymbolCircle.svelte'
  import BottomSheet2 from '$lib/components/BottomSheet2.svelte'
  import Button from '$lib/components/Button.svelte'
  import FullAvatar from '$lib/components/FullAvatar.svelte'
  import TelegramAvatar from '$lib/components/TelegramAvatar.svelte'
  import ActionSection from '$lib/components/ui/ActionSection.svelte'
  import { trGender } from '$lib/i18n'
  import { tonConnect } from '$lib/ton'
  import { getFlagEmoji } from '$lib/utils'

  const footerItems = [
    { text: 'Terms of service', link: '/' },
    { text: 'Privacy Policy', link: '/' },
    { text: 'Feedback', link: '/' },
  ]
</script>

{#if $apiCurrentUser}
  <BottomSheet2 class="h-[80vh] p-7 pt-0">
    <div class="mb-[-64px] -translate-y-1/2 self-center">
      <FullAvatar><TelegramAvatar twirlSize={64} /></FullAvatar>
    </div>

    <div class="flex grow flex-col gap-10 overflow-scroll pt-[24px] font-medium text-default">
      <div class="flex flex-col items-center gap-2">
        <div class="text-[28px]">{$apiCurrentUser.name}</div>

        <div class="flex items-center justify-between gap-6 self-stretch text-grey">
          <div class="flex grow basis-0 items-center justify-end gap-1">
            <span class="text-[20px]">👨</span>
            <span class="text-[14px] font-medium">{$trGender($apiCurrentUser.gender)}</span>
          </div>

          <div
            class="flex shrink-0 items-center gap-1 rounded-[30px] bg-chatbutton px-4 py-2 text-default"
          >
            <span><RatingIcon fill={'#FF004D'} /></span>
            <span class="text-[17px] font-medium">{$apiCurrentUser.rating}</span>
          </div>

          <div class="flex grow basis-0 items-center text-[14px] font-medium">
            {getFlagEmoji($apiCurrentUser.lang.toUpperCase())}
            {$apiCurrentUser.lang.toUpperCase()}
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <ActionSection on:click={() => appState.set('referrals')}>
          <div slot="left"><CoinsIcon /></div>
          Coins
          <div slot="right">{$apiCurrentUser.coins}</div>
        </ActionSection>
      </div>

      <div class="mt-auto flex flex-col gap-[10px]">
        {#if $tonConnect.connected}
          <div class="text-center text-[17px] font-medium text-grey">
            {$tonConnect.walletAddressShort}
          </div>
          <Button variant="secondary" on:click={() => tonConnect.disconnectWallet()}>
            <TonSymbolCircle size={22} />
            <div>Disconnect Wallet</div>
          </Button>
        {:else}
          <Button on:click={() => tonConnect.ensureConnectWallet()}
            ><TonSymbol />Connect Wallet</Button
          >
        {/if}
      </div>

      <!-- <div class="flex flex-col gap-4 px-4 text-[14px]">
        {#each footerItems as item}
          <div><a href={item.link} target="_blank">{item.text}</a></div>
        {/each}
      </div> -->
    </div>
  </BottomSheet2>
{/if}
