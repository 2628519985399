<script lang="ts">
  import { starsInput } from '$lib/stores'
  import { onMount } from 'svelte'

  export let min: number = 0
  export let max: number = 25000
  export let value: number = min
  export let placeholder: string | null = null

  let classes = ''
  export { classes as class }

  function normalize(e: KeyboardEvent) {
    const keyPressed = e.key

    const controlKeys = [
      'Backspace',
      'Delete',
      'Tab',
      'Escape',
      'Enter',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
    ]
    const isControlKey = controlKeys.includes(keyPressed)

    if (!isControlKey && (isNaN(Number(keyPressed)) || keyPressed === ' ')) {
      e.preventDefault()
    }
  }

  function handleInput(e: Event) {
    const input = e.target as HTMLInputElement
    let val = Number(input.value)

    if (val < min) val = min
    if (val > max) val = max

    value = Number(val).toString()
    starsInput.set(+value)
  }

  onMount(() => {
    starsInput.set(+value)
  })
</script>

<div class="p-4">
  <input
    class="coin-input {classes}"
    bind:value
    type="number"
    {min}
    {max}
    {placeholder}
    on:keydown={normalize}
    on:input={handleInput}
  />
</div>

<style lang="postcss">
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .coin-input {
    @apply bg-[transparent];
    @apply p-4;
    @apply text-center;
    @apply text-[32px];
    @apply font-semibold;
    @apply border-b-2;
    @apply border-b-grey/50;

    caret-color: transparent;
    outline: none;
  }

  .coin-input:focus {
    border-bottom: 2px solid var(--color-accent)
  }
</style>
