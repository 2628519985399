<script lang="ts">
  import { api } from '$lib/api'
  import { appCurrentRpsMatch } from '$lib/app-service'
  import RoundGrayButton from '$lib/components/ui/RoundGrayButton.svelte'
  import { fade } from 'svelte/transition'

  $: if ($appCurrentRpsMatch) selected = null

  let selected: string | null = null

  function invalidate() {
    $appCurrentRpsMatch = null
  }

  async function rock() {
    const res = await $api.client.emitWithAck('game:rps:move', { move: 'ROCK' })

    if (!res.ok) {
      if (res.error?.message !== 'session_move_is_not_valid') invalidate()
    } else {
      Telegram.WebApp.HapticFeedback.impactOccurred('rigid')
      selected = 'rock'
    }
  }

  async function paper() {
    const res = await $api.client.emitWithAck('game:rps:move', { move: 'PAPER' })

    if (!res.ok) {
      if (res.error?.message !== 'session_move_is_not_valid') invalidate()
    } else {
      Telegram.WebApp.HapticFeedback.impactOccurred('soft')
      selected = 'paper'
    }
  }

  async function scissors() {
    const res = await $api.client.emitWithAck('game:rps:move', { move: 'SCISSORS' })

    if (!res.ok) {
      if (res.error?.message !== 'session_move_is_not_valid') invalidate()
    } else {
      Telegram.WebApp.HapticFeedback.impactOccurred('heavy')
      selected = 'scissors'
    }
  }
</script>

{#if $appCurrentRpsMatch}
  <div
    transition:fade
    class="pointer-events-none absolute z-20 mt-10 flex h-full w-full select-none flex-row items-center justify-center gap-3"
  >
    <div
      class="pointer-events-auto flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-[#3E3E40]/50 text-2xl text-white"
      on:click={rock}
      class:selected={selected === 'rock'}
    >
      🧱
    </div>
    <div
      class="pointer-events-auto flex h-16 w-16 shrink-0 rotate-180 items-center justify-center rounded-full bg-[#3E3E40]/50 text-2xl text-white"
      on:click={scissors}
      class:selected={selected === 'scissors'}
    >
      ✂️
    </div>

    <div
      class="pointer-events-auto flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-[#3E3E40]/50 text-2xl text-white"
      on:click={paper}
      class:selected={selected === 'paper'}
    >
      📄
    </div>
  </div>
{/if}

<style>
  .selected {
    border-radius: 100%;
    background-color: #212121;
    color: #ffffff;
    transition: background-color 0.2s ease;
  }
</style>
