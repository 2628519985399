<script>
  import CrossIcon from '$lib/assets/CrossIcon.svelte'
  import { createEventDispatcher } from 'svelte'
  import { fade } from 'svelte/transition'
  import Button from '../Button.svelte'
  import { tr } from '$lib/i18n'
  import { api, apiCurrentUser } from '$lib/api'

  const eventDispatcher = createEventDispatcher()

  async function upgradeToVip() {
    const invoiceUrl = await $api.client.emitWithAck('vip:create-stars-invoice')

    if (!invoiceUrl.result || !invoiceUrl.ok) return

    const url = invoiceUrl.result.invoiceUrl

    Telegram.WebApp.openInvoice(url, (result) => {
      console.log('Transaction result', result)
    })
  }

  $: isMale = $apiCurrentUser?.gender !== 'FEMALE'
</script>

<div
  class="pointer-events-none absolute inset-0 z-30 flex h-full w-full items-center justify-center px-5 py-5"
>
  <div
    transition:fade
    class="pointer-events-auto relative flex h-full max-h-[540px] w-full max-w-[340px] select-none flex-col gap-4 rounded-2xl bg-[#3E3E40]/50 p-4 backdrop-blur-xl"
  >
    <div class="absolute right-3 top-3" on:click={() => eventDispatcher('close')}>
      <CrossIcon />
    </div>

    <div class="self-center">
      <p class="text-center text-[50px] font-bold">👑</p>
      <p class="text-center text-[32px] font-bold">
        {isMale ? $tr('vip_header_male') : $tr('vip_header_female')}
      </p>
    </div>

    <div class="flex flex-row justify-center gap-4">
      <p class="vip text-[32px] font-bold text-[transparent]">VIP</p>
      <p class="max-w-32 self-center text-[18px]">{$tr('vip_border')}</p>
    </div>

    <div class="flex flex-row justify-center gap-4">
      <p class="more-emojis marquee text-[32px]">
        <span>🦄🤩👽💋🐶🌚🌝🌈☄️💥❄️⛄️💨🦄🤩👽💋🐶🌚🌝🌈☄️💥❄️⛄️💨🦄🤩👽💋🐶🌚🌝🌈☄️💥❄️⛄️💨</span>
      </p>
      <p class="max-w-32 self-center text-[18px]">{$tr('vip_emoji')}</p>
    </div>

    <div class="flex flex-row justify-center gap-4">
      <p class="text-[32px]">🥰</p>
      <p class="max-w-32 self-center text-[18px]">{$tr('vip_free_status')}</p>
    </div>

    <div class="flex flex-row justify-center gap-4">
      <p class="animate-spin self-center text-[32px]">⌛️</p>
      <p class="max-w-32 self-center text-[18px]">{$tr('vip_timer')}</p>
    </div>

    <div class="mt-auto h-auto rounded-[22px] bg-[#CBCDFF] px-[1.5px] py-0.5">
      <Button variant="vip" class="w-full" on:click={upgradeToVip}>{$tr('buy_vip_long')}</Button>
    </div>
  </div>
</div>

<style>
  .vip {
    background: linear-gradient(to right, white 10%, #626aee 30%, #8a91fb 30%, #626aee 30%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textShine 2s linear infinite;
    font-weight: 700;
    font-size: 32px;
    font-style: italic;
  }

  @keyframes textShine {
    to {
      background-position: 200%;
    }
  }

  @keyframes scroll {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  .marquee {
    display: block;
    width: 32px;
    white-space: nowrap;
    overflow: hidden;
  }

  .marquee span {
    display: inline-block;
    padding-left: 5%;
    animation: scroll 60s infinite linear;
  }
</style>
