<script lang="ts">
  import { api, apiCurrentUser } from '$lib/api'
  import CoinsAddIcon from '$lib/assets/CoinsAddIcon.svelte'
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'
  import TelegramStarsSymbol from '$lib/assets/TelegramStarsSymbol.svelte'
  import BottomSheet2 from '$lib/components/BottomSheet2.svelte'
  import Button from '$lib/components/Button.svelte'
  import StarsInput from '$lib/components/StarsInput.svelte'
  import { tr } from '$lib/i18n'
  import { starsInput } from '$lib/stores'
  import { tonConnect } from '$lib/ton'
  import type { ApiTypes } from '@twixer/core'

  const numberFormat = new Intl.NumberFormat()

  $: coinsForStars = $starsInput * 10
  $: coinsFormatted = numberFormat.format($apiCurrentUser!.coins)
  $: coinsForStarsFormatted = numberFormat.format(Math.max(10, coinsForStars))

  let topUpShow = false
  let coinsOffers = $api.client.emitWithAck('coins:get-offers')
  let selectedOffer: ApiTypes.CoinsOffer | null = null

  async function purchase() {
    if (selectedOffer && (await tonConnect.ensureConnectWallet())) {
      const tx = await $api.client.emitWithAck('coins:create-transaction', {
        offerId: selectedOffer.id,
      })

      await tonConnect.purchase(tx.transaction)

      selectedOffer = null
      topUpShow = false
    }
  }

  async function openStarsTransaction() {
    const invoiceUrl = await $api.client.emitWithAck('coins:create-stars-invoice', {
      stars: Math.max(1, $starsInput),
    })

    if (!invoiceUrl.result || !invoiceUrl.ok) return

    const url = invoiceUrl.result.invoiceUrl

    Telegram.WebApp.openInvoice(url, (result) => {
      console.log('Transaction result', result)
    })
  }
</script>

<BottomSheet2 class="p-6" on:close>
  <div class="mb-[-50px] -translate-y-[50px] self-center">
    <CoinsIcon size={111} />
  </div>

  <div class="mt-4 flex flex-col gap-4 text-default">
    <div class="flex flex-col items-center font-medium">
      <div class="text-[28px]">{coinsFormatted}</div>
      <div class="text-[14px] text-grey">Coins</div>
    </div>

    <div class="h-4"></div>

    <div class="flex items-center justify-center w-full">
      <Button on:click={() => (topUpShow = true)}>
        <div class="flex grow items-center">
          <div class="mr-2">{$tr('add_coins')}</div>
          <CoinsAddIcon />
        </div>
      </Button>
    </div>
  </div>
</BottomSheet2>

{#if topUpShow}
  <BottomSheet2 on:close={() => (topUpShow = false)} class="px-6 pt-6">
    <div class="flex h-[65vh] flex-col items-center overflow-hidden">
      <div class="text-[24px] font-bold text-default">{$tr('add_coins')}</div>
      <div class="flex flex-grow flex-col px-2 py-6">
        <div class="text-center">
          <div class="font-semibold text-grey">
            {$tr('add_coins_stars_header')}
            <span class="inline-block translate-y-0.5"><TelegramStarsSymbol /></span>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <StarsInput class="max-w-[50vw]" min={1} max={2500} value={10}/>
          <div class="text-center text-grey">
            {$tr('you_will_get_coins')} <span class="text-coin">{coinsForStarsFormatted}</span>
            <span class="inline-block translate-y-1"> <CoinsIcon /></span>
          </div>
        </div>
      </div>
      <div class="w-full px-2 py-6">
        <Button
          class="w-full"
          on:click={() => {
            if (topUpShow) openStarsTransaction()
          }}>{$tr('buy')}</Button
        >
      </div>
    </div>
  </BottomSheet2>
{/if}