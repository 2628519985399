<script>
  import { analyticsTrack } from '$lib/analytics'
  import { api } from '$lib/api'
  import { appCurrentMatch } from '$lib/app-service'
  import AddFriendIcon from '$lib/assets/AddFriendIcon.svelte'
  import RoundGrayButton from '../ui/RoundGrayButton.svelte'

  async function addFriend() {
    analyticsTrack('VideoChat Add Friend Button Tapped')

    if ($appCurrentMatch) {
      const match = $appCurrentMatch

      const request = await $api.client.emitWithAck('user:send-friend-request', {
        receiverId: match.partner.id,
      })

      if (request.ok) {
        analyticsTrack('VideoChat Friend Request Sent', { receiverId: match.partner.id })

        // success
      } else {
        // error
      }
    }
  }
</script>

<!-- {#if $appCurrentMatch && !$appCurrentMatch?.partner.isFriend} -->
<div class="absolute bottom-3 right-[56px] flex select-none flex-row gap-3">
  <RoundGrayButton on:click={addFriend}><AddFriendIcon /></RoundGrayButton>
</div>
<!-- {/if} -->
