<script>
  import { api } from '$lib/api'
  import { appCurrentMatch, appCurrentRpsMatch, appReceivedRpsInvite } from '$lib/app-service'
  import Button from '$lib/components/Button.svelte'
  import FullAvatar from '$lib/components/FullAvatar.svelte'
  import TelegramAvatar from '$lib/components/TelegramAvatar.svelte'
  import { tr } from '$lib/i18n'
  import { fade } from 'svelte/transition'

  $: senderId = $appReceivedRpsInvite?.senderId
  $: senderIdTagged =
    !!senderId && senderId === $appCurrentMatch?.partner.id
      ? senderId + new Date().getSeconds()
      : undefined

  async function accept() {
    if (!senderId) {
      // log error

      senderId = undefined
      return
    }

    await $api.client.emitWithAck('game:rps:accept', {
      senderId,
    })

    senderId = undefined
  }

  async function decline() {
    if (!senderId) {
      // log error

      senderId = undefined
      return
    }

    await $api.client.emitWithAck('game:rps:decline', {
      senderId,
    })

    senderId = undefined
  }
</script>

{#if senderIdTagged}
  <div
    transition:fade|global
    class="relative flex w-[80vw] select-none flex-col items-center justify-center rounded-[20px] bg-[#3E3E40]/50 p-2 backdrop-blur-md"
  >
    <div class="absolute -top-20">
      <FullAvatar><TelegramAvatar twirlSize={32} userId={senderId} /></FullAvatar>
    </div>
    <div class="mt-8 flex justify-center p-4 text-center text-2xl font-bold">
      {$tr('rps_invite')}
    </div>
    <div class="flex w-full justify-between gap-3">
      <div class="basis-1/2">
        <Button class="w-full" variant="transparent" on:click={decline}>{$tr('decline')}</Button>
      </div>
      <div class="basis-1/2">
        <Button class="w-full" on:click={accept}>{$tr('accept')}</Button>
      </div>
    </div>
  </div>
{/if}
