<script lang="ts">
  import { analyticsTrack } from '$lib/analytics'
  import { api } from '$lib/api'
  import { appCallAt, appCurrentMatch, appService } from '$lib/app-service'
  import RoundGrayButton from '$lib/components/ui/RoundGrayButton.svelte'
  import { tr } from '$lib/i18n'
  import { secsToNow } from '$lib/utils'
</script>

<RoundGrayButton on:click>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 4.88031C2 4.32672 2.27658 3.80975 2.73703 3.50268C7.74068 0.165774 14.2593 0.165774 19.263 3.50268C19.7234 3.80975 20 4.32672 20 4.88031V12.4595C20 13.7862 19.4579 15.0799 18.4737 16.1025C18.1514 16.437 17.7564 16.7497 17.2659 17.0584L11 21L4.73409 17.0584C4.24357 16.7497 3.84853 16.437 3.52651 16.1025C2.54213 15.0799 2 13.7861 2 12.4595V4.88031ZM10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7V11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11V7ZM12 14C12 14.5523 11.5523 15 11 15C10.4477 15 10 14.5523 10 14C10 13.4477 10.4477 13 11 13C11.5523 13 12 13.4477 12 14Z"
      fill="currentColor"
    />
  </svg>
</RoundGrayButton>
