<script lang="ts">
  export let size: number = 15
</script>

<svg width={size} height={size} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M6.04271 3.41819C5.99397 3.56137 5.92573 3.73522 5.80387 4.01646C5.65212 4.36137 5.6428 4.59185 5.23845 4.74768C5.15558 4.77836 4.829 4.80904 4.51217 4.81415C3.71388 4.82703 2.90004 4.91428 2.10425 4.98289C1.48521 5.03914 1.45109 5.04937 1.31461 5.19766C1.01728 5.51469 1.09527 5.88285 1.52908 6.22034C1.66556 6.32261 1.87029 6.49646 1.9824 6.60384C2.37722 6.97201 2.84028 7.36574 3.02063 7.48846C3.08887 7.53448 3.25947 7.61119 3.39595 7.65721C4.36928 8.00333 5.96798 7.47111 6.94934 7.28393C7.45139 7.18166 7.55376 7.197 7.68536 7.37597C7.7731 7.49358 7.76335 7.71345 7.67074 7.79527C7.62687 7.83618 7.4124 7.9589 7.19305 8.07651C6.23444 8.59033 4.44394 9.19427 3.99549 10.2293C3.86389 10.5258 3.4057 12.3718 3.37645 12.7144C3.35208 12.9394 3.36183 12.97 3.54706 13.1592C3.67379 13.2973 3.7859 13.3638 3.86876 13.3638C3.99549 13.3638 4.59016 13.0365 4.99473 12.7502C5.47729 12.4076 5.78437 12.203 6.05734 12.0445C6.22306 11.9525 6.48628 11.7786 6.652 11.6559C7.37341 11.1292 7.57813 11.119 8.23616 11.5741C8.64608 11.8608 9.07893 12.1106 9.48886 12.3973C9.88026 12.6744 10.3092 12.982 10.7318 13.2104C11.0876 13.4047 11.2631 13.3893 11.4971 13.149C11.6433 13.0007 11.6531 12.9649 11.6336 12.7297C11.6238 12.5865 11.424 11.6866 11.19 10.7304C10.9609 9.77416 10.7708 8.94067 10.7708 8.8742C10.7708 8.20158 12.0508 7.5329 12.4817 7.11007C12.9139 6.69492 14.4201 5.86042 13.6467 5.1772C13.4956 5.05448 13.4176 5.03403 12.8911 4.98289C12.5694 4.95221 12.0381 4.92153 11.7116 4.91642C11.143 4.90765 10.001 4.95836 9.54248 4.65052C9.43037 4.57382 9.37188 4.47667 9.26465 4.19543C8.84625 3.14203 8.50854 2.04378 8.03631 1.01489C7.88521 0.677404 7.82184 0.636496 7.50501 0.636496C6.56807 0.636496 6.31172 2.65625 6.04271 3.41819Z"
    fill="#FFC814"
    stroke="url(#paint0_linear_3436_9787)"
    stroke-width="0.454545"
  />
  <defs>
    <linearGradient
      id="paint0_linear_3436_9787"
      x1="11.1367"
      y1="2.45468"
      x2="3.40945"
      y2="13.8183"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#CF8E0F" />
      <stop offset="0.387775" stop-color="#C6AB77" />
      <stop offset="1" stop-color="#694808" />
    </linearGradient>
  </defs>
</svg>
