<script>
  import { analyticsTrack } from '$lib/analytics'
  import { api } from '$lib/api'
  import { appCurrentMatch, appCurrentRpsMatch } from '$lib/app-service'
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'

  import GameIcon from '$lib/assets/GameIcon.svelte'
  import { fade } from 'svelte/transition'
  import RoundGrayButton from '../ui/RoundGrayButton.svelte'

  async function invitePlay() {
    analyticsTrack('VideoChat Play Game Button Tapped')

    if ($appCurrentMatch) {
      const match = $appCurrentMatch

      const request = await $api.client.emitWithAck('game:rps:create', {
        inviteeId: match.partner.id,
      })

      if (request.ok) {
        analyticsTrack('VideoChat Play Game Request Sent', { receiverId: match.partner.id })

        // success
      } else {
        // error
      }
    }
  }
</script>

{#if $appCurrentMatch}
  <div class="relative" transition:fade>
    <div class="absolute bottom-3 right-2 flex select-none flex-row gap-3">
      <RoundGrayButton on:click={invitePlay}><GameIcon /></RoundGrayButton>
    </div>
    <div class="absolute bottom-0 right-2 flex select-none flex-row">
      +15 <span class="ml-0.5 inline-block translate-y-1.5"><CoinsIcon size={12} /></span>
    </div>
  </div>
{/if}
