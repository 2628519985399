<script type="ts">
  export let online = true
</script>

<div class="h-2 w-2 rounded-full" class:offline={!online} class:online></div>

<style>
  .offline {
    background-color: #dc3545;
  }

  .online {
    background-color: #34ff23;
  }
</style>
