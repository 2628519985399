<script lang="ts">
  export let width = 104
  export let height = 87
</script>

<svg {width} {height} viewBox="0 0 104 87" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M26.3186 1.08875C19.4625 1.91989 13.3324 5.0012 8.5331 10.0691C3.61285 15.2182 0.850254 21.7254 0.487285 28.9827C-0.218488 42.504 10.5093 57.8497 31.9648 74.0672C38.8612 79.2973 50.698 86.7978 52.0087 86.8181C53.3598 86.8181 64.5311 79.8041 71.7905 74.3915C90.1003 60.7283 100.747 47.4503 103.187 35.1859C103.712 32.51 103.651 27.4015 103.066 24.5837C101.554 17.4075 97.6218 11.103 92.0159 6.86621C80.3606 -1.93175 64.148 -0.877613 53.9042 9.31909L52.049 11.1638L50.0325 9.17719C45.7777 4.96066 40.6357 2.3456 34.5862 1.2712C32.8318 0.967119 28.1939 0.86576 26.3186 1.08875Z"
    fill="#FF004D"
  />
</svg>
