<script lang="ts">
  // todo: make better animation for coins (something like logging +n/-n or idk)
  import { tweened } from 'svelte/motion'
  import { linear } from 'svelte/easing'
  import CoinProgressBar from './CoinProgressBar.svelte'
  import CoinProgressCounter from './CoinProgressCounter.svelte'
  import { apiCurrentUser } from '$lib/api'

  export let earning = false

  export let coinsPerRate: number
  export let rateMs: number

  $: coins = $apiCurrentUser?.coins ?? 0

  $: duration = rateMs / coinsPerRate
  $: if (coins) glow = true

  let glow = false

  const tweenedValue = tweened(0, {
    easing: linear,
  })

  $: if ($tweenedValue >= 1) {
    coins++

    Telegram.WebApp.HapticFeedback.impactOccurred('rigid')

    // reset the animation and then start it again
    tweenedValue.set(0, { duration: 0 })
    tweenedValue.set(1, { duration })
  }

  $: if (earning) {
    tweenedValue.set(0, { duration: 0 })
    tweenedValue.set(1, { duration })
  } else {
    tweenedValue.set(0, { duration: 0 })
  }
</script>

<div class="relative select-none">
  <div
    class="flex h-7 flex-row items-center justify-between overflow-visible rounded-[30px] bg-[#3E3E40]/50 px-2 backdrop-blur-[10px]"
    class:glow
    on:animationend={() => (glow = false)}
  >
    <div class="flex-grow">
      <CoinProgressBar ratio={$tweenedValue}></CoinProgressBar>
    </div>
    <div class="w-auto">
      <CoinProgressCounter farmedCoins={coins}></CoinProgressCounter>
    </div>
  </div>
</div>

<style>
  @keyframes glow {
    0% {
      box-shadow: 0 0 10px #ffef18;
    }
    100% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0);
    }
  }

  .glow {
    animation: glow 0.5s forwards;
  }
</style>
