<script lang="ts">
  import RoundGrayButton from '../ui/RoundGrayButton.svelte'
</script>

<RoundGrayButton on:click>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0041 3.48002C8.05173 3.48002 5.52223 5.29823 4.47765 7.87879L3.78153 7.21134C3.52021 6.96079 3.44703 6.56796 3.62849 6.25469C5.10136 3.71187 7.85213 2 11.0041 2C15.7084 2 19.5219 5.81354 19.5219 10.5178H18.0419C18.0419 6.63094 14.891 3.48002 11.0041 3.48002ZM3.96635 10.5178C3.96635 14.4046 7.11727 17.5556 11.0041 17.5556C14.0251 17.5556 16.6033 15.6518 17.6008 12.9761L18.357 13.5264C18.6365 13.7298 18.7412 14.1014 18.583 14.4089C17.17 17.1555 14.3074 19.0356 11.0041 19.0356C6.29987 19.0356 2.48633 15.222 2.48633 10.5178H3.96635Z"
      fill="white"
    />
    <path
      d="M3.36106 8.22607L1.28711 11.4793C1.16278 11.6744 1.30286 11.9298 1.53415 11.9298L5.18797 11.9298C5.41925 11.9298 5.55933 11.6743 5.435 11.4793L3.36106 8.22607Z"
      fill="white"
    />
    <path
      d="M18.6389 12.7036L16.5649 9.45036C16.4406 9.25534 16.5807 8.99991 16.812 8.99991L20.4658 8.99991C20.6971 8.99991 20.8372 9.25534 20.7128 9.45036L18.6389 12.7036Z"
      fill="white"
    />
  </svg>
</RoundGrayButton>
