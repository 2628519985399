<script lang="ts">
  import TelegramAvatar from '../TelegramAvatar.svelte'

  export let username: string
  export let comboCooldown: number
  export let senderId: string

  let combo = 0
  let comboReset: NodeJS.Timeout
  let giftPopup: HTMLElement | undefined

  let currentGift:
    | {
        name: string
        icon: string
        trigger: (arg: any, ...rest: any[]) => unknown
      }
    | undefined

  export function onGiftSent(gift: {
    name: string
    icon: string
    trigger: (arg: any, ...rest: any[]) => unknown
  }) {
    if (combo === 0) showGiftPopup()

    currentGift = gift

    setTimeout(() => {
      gift.trigger({})
    }, 1000)

    combo++

    clearTimeout(comboReset)
    comboReset = setTimeout(async () => {
      await hideGiftPopup()
      combo = 0
    }, comboCooldown)
  }

  function showGiftPopup() {
    if (!giftPopup) return

    const movement = giftPopup.animate(
      [
        {
          transform: 'translateY(30px)',
          opacity: 0,
        },
        {
          transform: 'translateY(0)',
          opacity: 1,
        },
      ],
      {
        duration: 500,
        easing: 'ease-in-out',
        fill: 'forwards',
      }
    )

    return movement.finished
  }

  function hideGiftPopup() {
    if (!giftPopup) return

    const animate = giftPopup.animate([{ opacity: 1 }, { opacity: 0 }], {
      fill: 'forwards',
      duration: 500,
      easing: 'ease-in',
    })

    return animate.finished
  }
</script>

<div class="flex items-center">
  <div
    bind:this={giftPopup}
    class="absolute inline-flex h-[56px] items-center justify-between rounded-full bg-[#3E3E40]/50 px-1 py-1 opacity-0 backdrop-blur-md"
  >
    <div class="mr-1 flex items-center">
      <div
        class="pointer-events-none flex h-12 w-12 shrink-0 select-none items-center justify-center rounded-full bg-[#F2F2F5]/10"
      >
        <div class="text-center text-[28px]">
          <TelegramAvatar twirlSize={16} userId={senderId} />
        </div>
      </div>
    </div>
    <div class="ml-1 flex flex-col justify-center -space-y-0.5">
      <div class="text-[13px] font-normal">{username}</div>
      <div class="whitespace-nowrap text-[17px] font-semibold">
        sent {currentGift?.name}
      </div>
    </div>
    <div class="ml-3 mr-2 flex items-center">
      <div class="text-[28px]">{currentGift?.icon}</div>
      <div class="translate-y-1 text-[19px] font-bold italic">x{combo}</div>
    </div>
  </div>
</div>
