<script lang="ts">
  import { analyticsTrack } from '$lib/analytics'
  import { apiCurrentUser } from '$lib/api'
  import { appService, appState } from '$lib/app-service'
  import TwixerLogo from '$lib/assets/TwixerLogo.svelte'
  import BottomSheet2 from '$lib/components/BottomSheet2.svelte'
  import ChatWithGenderSheet from '$lib/components/ChatWithGenderSheet.svelte'
  import Select from '$lib/components/Select.svelte'
  import StartVideoChatButton from '$lib/components/StartVideoChatButton.svelte'
  import UserAvatarButton from '$lib/components/UserAvatarButton.svelte'
  import { tr } from '$lib/i18n'
  import Account from './Account.svelte'
  import Match from './Match.svelte'
  import Referrals from './Referrals.svelte'
  import Button from '$lib/components/Button.svelte'

  let gendersShown = false
  let channelsShown = false
  let channels: string[] = []

  $: if (gendersShown) {
    analyticsTrack('StartVideoChat GenderSelection Tapped')
  }

  function startMatchmaking() {
    analyticsTrack('StartVideoChat Button Tapped')
    Telegram.WebApp.HapticFeedback.impactOccurred('medium')
    appService.startMatch()
  }
</script>

{#if $appState === 'match'}
  <Match />
{:else if $appState === 'referrals'}
  <div class="flex h-full flex-col items-center gap-4 bg-accent p-4 text-accent-dark">
    <Referrals />
  </div>
{:else if $appState === 'account'}
  <div class="flex h-full flex-col items-center gap-4 bg-accent p-4 text-accent-dark">
    <Account />
  </div>
{:else}
  <div class="flex h-full flex-col gap-4 bg-accent text-accent-dark">
    <div class="flex grow flex-col items-center p-4">
      <div class="flex w-full justify-between">
        {#if $apiCurrentUser}
          <Button height={40} variant={'white'} on:click={() => appState.set('referrals')}
            >{$tr('invite_home_button')}</Button
          >
          <UserAvatarButton userId={$apiCurrentUser.id} on:click={() => appState.set('account')} />
        {/if}
      </div>

      <div class="flex grow items-center">
        <TwixerLogo />
      </div>
    </div>

    <BottomSheet2 variant="static" backdrop={false} class="shrink-0 p-8">
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-2">
          <Select icon="👫" value={$tr('girls_and_guys')} on:click={() => (gendersShown = true)} />
          <!-- <Select icon="🚀" value="Channel" on:click={() => (channelsShown = true)} /> -->
        </div>

        <StartVideoChatButton on:click={() => startMatchmaking()} />
      </div>
    </BottomSheet2>
  </div>

  <ChatWithGenderSheet bind:shown={gendersShown} />
  <!-- <ChannelSelectSheet bind:shown={channelsShown} bind:channels /> -->
{/if}
