<script lang="ts">
  export let size = 22
</script>

<svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="icon_close">
    <path
      id="Union"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.8539 9.96773C21.424 9.96773 21.8861 10.4299 21.8861 11C21.8861 11.5701 21.424 12.0322 20.8539 12.0322L12.0323 12.0322L12.0323 20.8539C12.0323 21.424 11.5701 21.8861 11 21.8861C10.4299 21.8861 9.96773 21.424 9.96774 20.8539L9.96774 12.0322L1.14612 12.0323C0.576018 12.0323 0.11386 11.5701 0.113861 11C0.11386 10.4299 0.576018 9.96774 1.14612 9.96774L9.96774 9.96773L9.96774 1.14611C9.96774 0.576013 10.4299 0.113855 11 0.113855C11.5701 0.113855 12.0323 0.576012 12.0323 1.14611L12.0323 9.96773L20.8539 9.96773Z"
      fill="white"
    />
  </g>
</svg>
