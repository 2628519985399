<button
  class="bg-bg-secondary flex cursor-pointer select-none items-center gap-4 rounded-2xl px-4 py-[14px] text-[17px] font-medium active:brightness-90"
  on:click
>
  <slot name="left" />

  <div class="grow text-left">
    <slot />
  </div>

  <slot name="right" />

  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
      <path
        d="M1.25 1.91666L8.33333 8.99999L1.25 16.0833"
        stroke="#464648"
        stroke-width="1.33333"
        stroke-linecap="round"
      />
    </svg>
  </div>
</button>
