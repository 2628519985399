<script lang="ts">
  import { api, apiCurrentUser } from '$lib/api'
  import NextButton from '../NextButton.svelte'
  import RoundGrayButton from '../ui/RoundGrayButton.svelte'
  import FriendsButton from './FriendsButton.svelte'
  import ReportButton from './ReportButton.svelte'
  import SendGiftButton from './SendGiftButton.svelte'
  import SwitchCamButton from './SwitchCamButton.svelte'
  import { localStream } from '$lib/stream'
  import { appCurrentMatch, appService } from '$lib/app-service'
  import ModerationMenuOpenButton from './Moderation/ModerationMenuOpenButton.svelte'
  import OpenGiftsShopButton from '../Gifts/OpenGiftsShopButton.svelte'
  import GiftsShop from '../Gifts/GiftsShop.svelte'

  $: isModerator = $apiCurrentUser?.isModerator ?? false

  $: gifts = $api.client.emitWithAck('gift:get-available')

  function switchCamera() {
    localStream.switchCamera()
  }

  function dropStatus() {
    if (!$apiCurrentUser?.isModerator || !$appCurrentMatch) return

    $api.client.emit('mod:drop-status', {
      userId: $appCurrentMatch.partner.id,
    })
  }

  async function sendGift() {
    if (!$appCurrentMatch) return

    const sendResult = await appService.sendGift($appCurrentMatch.partner.id, 1)

    if (!sendResult.ok) {
      // log error
    }
  }

  $: callStarted = !!$appCurrentMatch

  let giftShopOpened = false
</script>

<div
  class="pointer-events-auto absolute bottom-6 left-3 right-3 flex items-center justify-between gap-3"
>
  <div class="pointer-events-auto flex select-none gap-3">
    <SwitchCamButton on:click={switchCamera} />
    {#if isModerator}
      <ModerationMenuOpenButton />
      <RoundGrayButton on:click={() => dropStatus()}>💬</RoundGrayButton>
    {:else}
      <FriendsButton />
    {/if}
  </div>

  {#await gifts then availableGifts}
    <div class="absolute inset-0 m-auto flex h-max w-max select-none">
      <div on:click={() => sendGift()}>
        <SendGiftButton content={availableGifts[0].icon} price={availableGifts[0].price} />
      </div>
      <div on:click={() => (giftShopOpened = true)}>
        <OpenGiftsShopButton
          on:click={() => {
            alert(1)
            giftShopOpened = true
          }}
        />
      </div>
    </div>
  {/await}

  <div class="flex gap-3">
    {#if isModerator}
      <FriendsButton />
    {/if}

    {#if callStarted}
      <ReportButton />
      <NextButton />
    {/if}
  </div>
</div>

{#if giftShopOpened}
  <div class="pointer-events-auto">
    <GiftsShop on:close={() => (giftShopOpened = false)} />
  </div>
{/if}
