<script lang="ts">
  import { api, apiCurrentUser } from '$lib/api'
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'
  import type { ApiTypes } from '@twixer/core'
  import BottomSheet2 from '../BottomSheet2.svelte'
  import { appCurrentMatch } from '$lib/app-service'
  import { createEventDispatcher } from 'svelte'
    import AccountCoins from '../../../routes/AccountCoins.svelte'

  const eventDispatcher = createEventDispatcher()

  let giftsPromise = $api.client.emitWithAck('gift:get-available')

  async function sendGift(gift: ApiTypes.Gift) {
    if (!$appCurrentMatch) return

    const currentPartner = $appCurrentMatch.partner

    const res = await $api.client.emitWithAck('gift:send', {
      giftId: gift.id,
      receiverId: currentPartner.id
    })

    if (res.ok) eventDispatcher('close')
    else coinsSheetShow = true
  }

let coinsSheetShow = false
</script>

<BottomSheet2 class="relative h-[45vh] overflow-y-scroll p-6" on:close>
  {#if $apiCurrentUser}
    <div class="-mb-6 flex select-none overflow-x-hidden overflow-y-scroll overscroll-none p-3">
      <div class="-mx-3 -mt-3 flex flex-grow flex-wrap justify-center gap-3">
        {#await giftsPromise then gifts}
          {#each gifts as gift}
            <div
              class="flex h-[110px] w-[100px] flex-col items-center justify-center rounded-[20px] bg-chatbutton/50 p-4 text-center"
              on:click={() => sendGift(gift)}
            >
              <div class="mb-2 text-[32px]">{gift.icon}</div>

              <div
                class="flex h-7 flex-row items-center justify-between rounded-2xl bg-shopbubble px-2 backdrop-blur-[10px]"
              >
                <span class="inline-block"><CoinsIcon size={19} /></span>
                <div class="items-center pl-2 text-coin">{gift.price}</div>
              </div>
            </div>
          {/each}
        {/await}
      </div>
    </div>
  {/if}
</BottomSheet2>

{#if coinsSheetShow}
  <AccountCoins on:close={() => (coinsSheetShow = false)} />
{/if}