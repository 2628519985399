<script lang="ts">
  import { tr } from '$lib/i18n'
  import Button from '../Button.svelte'
  import InviteFriendSheet from './InviteFriendSheet.svelte'
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'

  let openFriendsInviteSheet = false
</script>

<Button class="w-full" variant="coin" on:click={() => (openFriendsInviteSheet = true)}>
  <div class="-translate-y-[2px]">{$tr('invite_friend_button')}</div>
  <div class="flex justify-end gap-1 text-[12px]">
    <span class="-mr-0.5 inline-block translate-y-0.5"><CoinsIcon fill={'white'} size={14} /></span>
    <div class="translate-y-[1px] items-center text-white">+1000</div>
  </div>
</Button>

{#if openFriendsInviteSheet}
  <InviteFriendSheet on:close={() => (openFriendsInviteSheet = false)} />
{/if}
