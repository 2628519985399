<script lang="ts">
  import { api, apiCurrentUser } from '$lib/api'
  import Twirl from '$lib/assets/Twirl.svelte'
  import { avatarCache } from '$lib/stores'
  import { onMount } from 'svelte'

  export let twirlSize: number
  export let userId: string = $apiCurrentUser?.id ?? ''

  onMount(async () => {
    if (!!$avatarCache[userId]) {
      return
    }

    const avatar = await $api.client.emitWithAck('user:avatar', { userId })

    $avatarCache[userId] = avatar.avatar
  })

  $: loadedAvatar = $avatarCache[userId]
  $: loaded = loadedAvatar !== undefined
</script>

{#if !loaded}
  <div class="animate-spin"><Twirl width={twirlSize} height={twirlSize} /></div>
{:else if loadedAvatar == null}
  🐼
{:else}
  <img class="rounded-full" src={loadedAvatar} />
{/if}
