<script lang="ts">
  export let size = 22
</script>

<svg width={size} height={size} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="fill-default"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.2378 4.05234C17.6409 3.64922 18.2945 3.64922 18.6977 4.05234C19.1008 4.45546 19.1008 5.10905 18.6977 5.51218L12.4598 11.75L18.6977 17.9878C19.1008 18.391 19.1008 19.0445 18.6977 19.4477C18.2945 19.8508 17.6409 19.8508 17.2378 19.4477L11 13.2098L4.76217 19.4477C4.35905 19.8508 3.70546 19.8508 3.30234 19.4477C2.89922 19.0445 2.89922 18.391 3.30234 17.9878L9.54017 11.75L3.30234 5.51218C2.89922 5.10905 2.89922 4.45546 3.30234 4.05234C3.70546 3.64922 4.35905 3.64922 4.76217 4.05234L11 10.2902L17.2378 4.05234Z"
  />
</svg>
