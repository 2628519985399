<script lang="ts">
  import HeartIcon from '$lib/assets/HeartIcon.svelte'
  import { onMount } from 'svelte'

  export let startX: number
  export let startY: number
  export let endX: number
  export let endY: number
  export let scaleMult: number

  export let reaction: string | undefined

  let el: HTMLElement

  function getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min
  }

  function getRandomInt(min: number, max: number) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  onMount(async () => {
    const { width, height } = el.getBoundingClientRect()

    const startXMiddle = startX - width / 2 + getRandomInt(-10, 10)
    const startYMiddle = startY - height / 2 + getRandomInt(-10, 10)

    const endXMiddle = endX - width / 2
    const endYMiddle = endY - height / 2

    const initAngle = getRandomInt(-45, 45)
    const targetScale = 0.7 * scaleMult

    const move = el.animate(
      [
        {
          transform: `translate(${startXMiddle}px, ${startYMiddle}px) scale(0.2) rotate(${initAngle}deg)`,
          opacity: 0,
        },
        {
          transform: `translate(${startXMiddle}px, ${startYMiddle}px) scale(${targetScale}) rotate(${initAngle}deg)`,
          opacity: 1,
          offset: 0.15,
        },
        {
          transform: `translate(${startXMiddle}px, ${startYMiddle}px) scale(${targetScale}) rotate(0)`,
          opacity: 1,
          offset: 0.25,
        },
        {
          transform: `translate(${endXMiddle}px, ${endYMiddle}px) scale(0.2) rotate(0deg)`,
        },
      ],
      {
        fill: 'forwards',
        duration: 1000,
      }
    )

    const opacity = el.animate([{ opacity: 1 }, { opacity: 0 }], {
      fill: 'forwards',
      duration: 500,
      delay: 600,
      easing: 'ease-in',
    })

    const blur = el.animate([{ filter: 'blur(0)' }, { filter: 'blur(40px)' }], {
      fill: 'forwards',
      duration: 1000,
      delay: 400,
      easing: 'ease-in',
    })

    await Promise.all([move.finished, opacity.finished, blur.finished])

    queueMicrotask(() => el.remove())
  })
</script>

<div class="anim" bind:this={el}>
  {#if reaction}
    <div class="text-[100px]">{reaction}</div>
  {:else}
    <HeartIcon />
  {/if}
</div>

<style>
  .anim {
    position: absolute;
    will-change: transform, opacity, filter;
    transform-origin: 50% 50%;
    filter: drop-shadow(0px 4px 35px rgba(255, 0, 77, 0.75));
  }
</style>
