<script lang="ts">
  import { apiCurrentUser } from '$lib/api'
  import { appRpsMatchEnded } from '$lib/app-service'
  import TelegramAvatar from '$lib/components/TelegramAvatar.svelte'
  import { fade, slide } from 'svelte/transition'
  import CoinsIcon from '$lib/assets/CoinsIcon.svelte'

  let giftPopup: HTMLElement | undefined

  function hidePopup() {
    if (!giftPopup) return

    const animate = giftPopup.animate([{ opacity: 1 }, { opacity: 0 }], {
      fill: 'forwards',
      duration: 500,
      easing: 'ease-in',
    })

    return animate.finished
  }

  function showPopup() {
    if (!giftPopup) return

    Telegram.WebApp.HapticFeedback.impactOccurred('rigid')

    const movement = giftPopup.animate(
      [
        {
          transform: 'translateY(30px)',
          opacity: 0,
        },
        {
          transform: 'translateY(0)',
          opacity: 1,
        },
      ],
      {
        duration: 500,
        easing: 'ease-in-out',
        fill: 'forwards',
      }
    )

    return movement.finished
  }

  $: if ($appRpsMatchEnded) {
    showPopup()

    setTimeout(hidePopup, 2500)
  }

  $: if (!$appRpsMatchEnded) {
    hidePopup()
  }

  const picks: {
    [id: string]: string
  } = {
    SCISSORS: '✂️',
    PAPER: '📄',
    ROCK: '🧱',
  }

  $: draw = $appRpsMatchEnded?.winnerId == null || $appRpsMatchEnded?.winnerId == undefined
  $: win = !draw && $appRpsMatchEnded?.winnerId !== $appRpsMatchEnded?.opponentId
  $: lose = !draw && !win
</script>

<div class="flex h-full w-full items-center justify-center">
  <div
    transition:fade
    bind:this={giftPopup}
    class="inline-flex h-[56px] items-center justify-between gap-2 rounded-full bg-[#3E3E40]/50 px-1 py-1 backdrop-blur-md"
  >
    <div class="mr-1 flex items-center">
      <div
        class="pointer-events-none relative flex h-12 w-12 shrink-0 select-none items-center justify-center rounded-full bg-[#F2F2F5]/10"
      >
        <div class="text-center text-[28px]">
          <TelegramAvatar twirlSize={16} userId={$apiCurrentUser?.id} />
        </div>

        {#if win}
          <div
            transition:slide
            class="absolute -top-10 flex select-none flex-row rounded-xl bg-bg-secondary p-1 text-coin"
          >
            +15 <span class="ml-0.5 inline-block translate-y-1.5"><CoinsIcon size={12} /></span>
          </div>
        {/if}
      </div>
    </div>

    <div class:win class:lose class:draw>
      {draw ? 'DRAW' : lose ? 'LOSE' : 'WIN'}
    </div>

    <div class="text-[24px]">{picks[$appRpsMatchEnded?.yourPick ?? '']}</div>
    <div class="-mx-1">VS</div>
    <div class="text-[24px]">{picks[$appRpsMatchEnded?.opponentPick ?? '']}</div>

    <div class:win={lose} class:lose={win} class:draw>
      {draw ? 'DRAW' : win ? 'LOSE' : 'WIN'}
    </div>

    <div class="ml-1 flex items-center">
      <div
        class="pointer-events-none flex h-12 w-12 shrink-0 select-none items-center justify-center rounded-full bg-[#F2F2F5]/10"
      >
        <div class="text-center text-[28px]">
          <TelegramAvatar twirlSize={16} userId={$appRpsMatchEnded?.opponentId} />
        </div>

        {#if lose}
          <div
            transition:slide
            class="absolute -top-10 flex select-none flex-row rounded-xl bg-bg-secondary p-1 text-coin"
          >
            +15 <span class="ml-0.5 inline-block translate-y-1.5"><CoinsIcon size={12} /></span>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .win {
    background: linear-gradient(to right, white 5%, #bada55 30%, #bada44 70%, #25ce7f 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textShine 1s linear infinite;
    font-weight: 700;
    font-size: 24px;
    font-style: italic;
  }

  @keyframes textShine {
    to {
      background-position: 200%;
    }
  }

  .draw {
    color: #ababab;
    font-weight: 700;
    font-size: 18px;
  }

  .lose {
    color: #fd283d;
    font-weight: 700;
    font-size: 18px;
  }
</style>
