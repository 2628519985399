<script lang="ts">
  export let size = 20
  export let glow = false

  export let fill = '#FFB31A'
</script>

<svg
  class:glow
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    id="coins"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM11.587 7.81565L10 4L8.41298 7.81565L4.29366 8.1459L7.43215 10.8343L6.47329 14.8541L10 12.7L13.5267 14.8541L12.5679 10.8343L15.7063 8.1459L11.587 7.81565Z"
    {fill}
  />
</svg>

<style>
  .glow {
    filter: drop-shadow(0px 0px 5px rgba(255, 239, 24, 0.2));
  }
</style>
