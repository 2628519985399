<script lang="ts">
  import Twirl from '$lib/assets/Twirl.svelte'
  import { locale, tr } from '$lib/i18n'
  import { onMount } from 'svelte'
  import OnlineDotIcon from '../ui/OnlineDotIcon.svelte'
  import { fade } from 'svelte/transition'
  import Button from '../Button.svelte'
  import { dev } from '$app/environment'
  import { apiCurrentUser } from '$lib/api'
  import UsersOnlineStatus from './UsersOnlineStatus.svelte'
  
  $: isModerator = $apiCurrentUser?.isModerator ?? false

  const timeout = dev ? 3000 : 15000
  const numBanners = 3
  let activeBanner = 1
  let timer: any

  function nextBanner() {
    if (activeBanner < numBanners) {
      activeBanner = activeBanner + 1
    } else {
      activeBanner = 1
    }
  }

  onMount(() => {
    timer = setInterval(nextBanner, timeout)

    return () => {
      clearInterval(timer)
    }
  })

  $: communityLink =
    $locale === 'ru' ? 'https://t.me/twixercommunity' : 'https://t.me/twixercommunity_en'
</script>

<div class="relative flex flex-col items-center px-5 py-6">
  <div class="shrink-0 animate-spin text-accent will-change-transform"><Twirl /></div>

  <div class="flex grow flex-col items-center justify-between self-stretch">
    <div class="flex items-center p-3 text-[17px] font-medium text-accent">
      {$tr('finding_someone')}
    </div>

    {#if activeBanner === 1}
      <div in:fade class="">
        <UsersOnlineStatus />
      </div>
    {:else if activeBanner === 2}
      <div in:fade class="flex flex-col items-center gap-2 text-[13px]">
        <div class="flex items-center gap-1 font-medium text-default">
          <OnlineDotIcon />
          {$tr('banner_evening_better')}
        </div>
        <div class="text-center font-normal text-grey">
          {@html $tr('banner_evening_description')}
        </div>
      </div>
    {:else}
      <div in:fade class="flex flex-col gap-2 self-stretch px-2">
        <div class="self-center text-[13px]">{$tr('banner_know_first')}</div>
        <a class="inline-block" href={communityLink} target="_blank">
          <Button class="w-full">{$tr('banner_join_community')}</Button>
        </a>
      </div>
    {/if}
  </div>
</div>
