<script lang="ts">
  import { api, apiCallRemoteStream, apiCurrentUser } from '$lib/api'
  import Call from '../lib/components/Call/Call.svelte'
  import { localStream } from '$lib/stream'
  import LocalVideo from '$lib/components/LocalVideo.svelte'
  import RemoteVideo from '$lib/components/RemoteVideo.svelte'
  import MatchmakingLoader from '$lib/components/Call/MatchmakingLoader.svelte'
  import Chat from '$lib/components/Chat/Chat.svelte'
  import {
    appCurrentMatch,
    appService,
    appReceivedFriendRequest,
    appReceivedGift,
    appRpsMatchEnded,
  } from '$lib/app-service'
  import UserInfo from '$lib/components/Call/UserInfo.svelte'
  import { flyingHeart, flyingHeartsContainer, getCenterCoords } from '$lib/actions/flying-heart'
  import UsersOnlineStatus from '$lib/components/Call/UsersOnlineStatus.svelte'
  import CoinProgress from '$lib/components/Call/CoinProgress/CoinProgress.svelte'
  import type { ApiTypes } from '@twixer/core'
  import { onMount } from 'svelte'
  import { baseGift, giftContainer } from '$lib/actions/gifts/gifts'
  import GiftPopup from '$lib/components/Call/GiftPopup.svelte'
  import Shop from './Shop.svelte'
  import { openedShopToday } from '$lib/stores'
  import FriendRequestPopup from '$lib/components/Call/FriendRequestPopup.svelte'
  import CallBottomPanel from '$lib/components/Call/CallBottomPanel.svelte'
  import CurrentMatchAge from '$lib/components/Call/Moderation/CurrentMatchAge.svelte'
  import ChangeMatchAgePanel from '$lib/components/Call/Moderation/ChangeMatchAgePanel.svelte'
  import FriendAddButton from '$lib/components/Call/FriendAddButton.svelte'
  import AccountCoins from './AccountCoins.svelte'
  import Referrals from './Referrals.svelte'
  import RockButton from '$lib/components/Games/RPS/RPSButtonsPanel.svelte'
  import RpsInvitePopup from '$lib/components/Games/RPS/RPSInvitePopup.svelte'
  import GameInviteButton from '$lib/components/Call/GameInviteButton.svelte'
  import RpsButtonsPanel from '$lib/components/Games/RPS/RPSButtonsPanel.svelte'
  import GameResultPopup from '$lib/components/Games/RPS/GameResultPopup.svelte'

  $: isModerator = $apiCurrentUser?.isModerator ?? false

  let chatShown = false
  let coinsSheetShow = false

  let partnerRatingEl: HTMLElement | undefined

  const heart = flyingHeart()

  function addRating(ev: MouseEvent) {
    if ($appCurrentMatch && partnerRatingEl) {
      Telegram.WebApp.HapticFeedback.impactOccurred('soft')

      const center = getCenterCoords(partnerRatingEl)
      heart.trigger({
        fromX: ev.x,
        fromY: ev.y,
        toX: center.x,
        toY: center.y,
        reaction: $apiCurrentUser?.cosmetics.reaction?.emoji,
      })
      appService.addRating($appCurrentMatch.partner.id)
    }
  }

  let giftPopup: GiftPopup
  let shopSheetShow: boolean = false

  let currentRate: ApiTypes.CoinFarmRate | undefined
  let giftSender: { name: string; id: string } | undefined

  onMount(async () => {
    const rates = (await $api.client.emitWithAck('farm:get-rates')).rates
    currentRate = rates.find((rate) => rate.state == 'inCall')
  })

  $: if ($appReceivedGift) {
    const event = $appReceivedGift

    giftSender = event.sender
    animateFallingGift({ ...event.gift })
  }

  function animateFallingGift(gift: { name: string; icon: string }) {
    Telegram.WebApp.HapticFeedback.impactOccurred('heavy')
    giftPopup?.onGiftSent({ ...gift, trigger: baseGift(gift.icon).trigger })
  }

  $: friendSenderId = $appReceivedFriendRequest?.senderId

  $: if (friendSenderId) {
    showFriendPopup = true
  }

  let showFriendPopup = false
</script>

<!-- 
<svelte:window
  on:focus={() => {
    if ($localStream?.videoTrack.readyState === 'ended') {
      endCall()
      appState.set('home')
    }
  }}
/> -->

<div class="grid h-full grid-rows-2">
  {#if $appCurrentMatch}
    <Call matchId={$appCurrentMatch.matchId} />
  {:else}
    <MatchmakingLoader />
  {/if}

  <!-- Android autoplay fix -->
  <div class="relative" class:hidden={!$appCurrentMatch}>
    <div class="h-full w-full" on:click={(ev) => addRating(ev)}>
      <RemoteVideo mediaStream={$apiCallRemoteStream} />
    </div>
    <div class="absolute bottom-3 left-1/2 z-10 -translate-x-1/2">
      <UsersOnlineStatus />
    </div>
    <CurrentMatchAge />
    <FriendAddButton />
    <GameInviteButton />
  </div>

  <div class="relative">
    {#if $localStream?.videoTrack && !!currentRate && !!$apiCurrentUser}
      <div class="absolute z-10 flex w-full flex-row justify-between p-2">
        <div
          class="flex w-full select-none justify-start"
          on:click={() => (shopSheetShow = true)}
          class:glow={!$openedShopToday}
        >
          <UserInfo user={$apiCurrentUser} bind:ratingEl={partnerRatingEl} />
        </div>
        <div class="flex justify-end" on:click={() => (coinsSheetShow = true)}>
          <CoinProgress
            earning={$appCurrentMatch !== null}
            rateMs={currentRate.millis}
            coinsPerRate={currentRate.amount}
          />
        </div>
      </div>
      <div class="absolute bottom-[30%] z-20 flex w-full items-center justify-center">
        <FriendRequestPopup />
      </div>
      <div class="absolute bottom-[30%] z-10 flex w-full items-center justify-center">
        <RpsInvitePopup />
      </div>
    {/if}

    <RpsButtonsPanel />
    <ChangeMatchAgePanel />

    <div on:click={(ev) => addRating(ev)}>
      <LocalVideo
        videoTrack={$localStream?.videoTrack ?? null}
        mirror={$localStream?.isVideoUserFacing ?? false}
      />
    </div>
  </div>

  <div
    bind:this={$flyingHeartsContainer}
    class="pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-50"
  ></div>

  <div
    bind:this={$giftContainer}
    class="pointer-events-none fixed bottom-0 left-3 right-0 top-0 z-50 flex w-max"
  >
    {#if giftSender}
      <GiftPopup
        bind:this={giftPopup}
        username={giftSender.name}
        senderId={giftSender.id}
        comboCooldown={2000}
      />
    {/if}
  </div>

  <div class="pointer-events-none fixed z-50 h-full w-full">
    {#if $appRpsMatchEnded}
      <GameResultPopup />
    {/if}
  </div>

  <!-- Safari IOS fix (need large absolute container) -->
  <div class="pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-10">
    {#if $appCurrentMatch}
      <div class="pointer-events-auto absolute left-0 right-0 top-0 flex justify-between p-3">
        <UserInfo user={$appCurrentMatch.partner} bind:ratingEl={partnerRatingEl} />
      </div>
    {/if}

    <CallBottomPanel />
  </div>
</div>

{#if chatShown}
  <Chat on:clickOutside={() => (chatShown = false)} />
{/if}

{#if coinsSheetShow}
  <Referrals on:close={() => (coinsSheetShow = false)} />
{/if}

{#if shopSheetShow}
  <Shop
    on:close={() => {
      $openedShopToday = true
      shopSheetShow = false
    }}
  />
{/if}

<style>
  @keyframes glow {
    0% {
      filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.2));
    }
    50% {
      filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.8));
    }
    100% {
      filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.2));
    }
  }

  .glow {
    animation: glow 1s infinite;
  }
</style>
