<script lang="ts">
  import { apiCurrentUser } from '$lib/api'
  import FriendsIcon from '$lib/assets/FriendsIcon.svelte'
  import RoundGrayButton from '$lib/components/ui/RoundGrayButton.svelte'
  import FriendsList from './FriendsList.svelte'

  let showFriendsList = false
</script>

<RoundGrayButton on:click={() => (showFriendsList = true)}>
  <FriendsIcon />
</RoundGrayButton>

{#if showFriendsList}
  <FriendsList
    on:close={() => {
      showFriendsList = false
    }}
  />
{/if}
